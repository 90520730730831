import { Autocomplete, LoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import Select from 'react-select';
import store from '../redux/Store'
import moment from 'moment';
import { ApiCall, ApiKey } from '../common/Apikey';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import VerifyModel from './VerifyModel';
import $, { event } from 'jquery';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from "react-tooltip";
import AddonModel from './AddonModel';

function CharterBook() {

  const AutocompleteRestrict = {
    types: ['geocode', 'establishment'],
    componentRestrictions: { country: ["sg"] }, //, "my"
  };

  const cartData = useSelector((state) => state.cart.AddCart);

  let timeConstraints = {
    minutes: {
      step: 5
    }
  }

  const [from_places, setFromPlaces] = useState("")
  const [from_lat, setFromLat] = useState("")
  const [from_lon, setFromLon] = useState("")
  const [pickup_location, setPickupLocation] = useState("")
  const [to_places, setToPlaces] = useState("")
  const [to_lat, setToLat] = useState("")
  const [to_lon, setToLon] = useState("")
  const [drop_location, setDropLocation] = useState("")
  const [main_category, setMainCategory] = useState("")
  const [sub_category, setSubCategory] = useState("")
  const [vehicle_type, setVehicleType] = useState("")
  const [vehicle_name, setVehicleName] = useState("")
  const [ChrDate, setChrDate] = useState("")
  const [ChrTime, setChrTime] = useState("")
  const [hrs, sethrs] = useState("2");
  const [short_name, setShortName] = useState("")
  const [main_name, setMainName] = useState("")
  const [email, setEmail] = useState("")
  const [cc, setCc] = useState("+65")
  const [phone, setPhone] = useState("")
  const [comment, setComment] = useState("")
  const [formError, setFormError] = useState({ pickup: "", drop: "", mainc: "", sub: "", vehicle: "", hour: "", short: "", main: "", cc: "", phone: "", date: "", time: "", emailErr: "" })

  const [email_verify, setEmailVerify] = useState(0)
  const [phone_verify, setPhoneVerify] = useState(0)
  const [country_code, setCountryCode] = useState([]);
  const [main_disable, setMainDisable] = useState(0)
  const [cart_button, setCartButton] = useState(0);

  const [mailverifybtn, setmailverifybtn] = useState(0)
  const [mobileverifybtn, setmobileverifybtn] = useState(0)

  // max and min hours
  const [MaxHours, setMaxHours] = useState(14);
  const [MinHours, setMinHours] = useState(2);
  const [HoursShow, setHoursShow] = useState(0);

  // max passenger and luggage
  const [MaxPassenger, setMaxPassenger] = useState(1);
  const [MaxLuggage, setMaxLuggage] = useState(0);
  const [vehicleshow, setvehicleshow] = useState(0);

  const maxpassengerIn = (event) => {
    let value = MaxPassenger;
    value++;
    setMaxPassenger(value)
  }

  const maxpassengerOut = (event) => {
    let value = MaxPassenger;
    if (value > 1) {
      value--
    }
    setMaxPassenger(value)
  }

  const maxluggageIn = (event) => {
    let value = MaxLuggage;
    value++;
    setMaxLuggage(value)
  }

  const maxluggageOut = (event) => {
    let value = MaxLuggage;
    if (value > 0) {
      value--
    }
    setMaxLuggage(value)
  }

  const [main_option, setMainOption] = useState([
    { value: '', name: "main_category", label: <div>Main Category</div> }
  ])
  const [sub_option, setSubOption] = useState([
    { value: '', name: 'sub_category', label: <div>Sub Category</div> },
  ])
  const [vehicle_option, setVehicleOption] = useState([
    { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
  ])

  const [fareData, setFareData] = useState([])

  const onDateChange = (event) => {
    setChrDate(event)
  }

  const onTimeChange = (event) => {
    const timeValue = new Date(event);
    const formattedTime = moment(timeValue).format('hh:mm a');
    setChrTime(formattedTime)
  }

  const HrsIn = (event) => {
    let value = hrs;
    if (value != MaxHours) {
      value++;
      sethrs(value)
    }
  }

  const HrsOut = (event) => {
    let value = hrs;
    if (value > MinHours) {
      value--
    }
    sethrs(value)
  }

  let timeProps = {
    placeholder: 'Time'
  }

  // addon
  const [AddonShow, setAddonShow] = useState(0);
  const [AddonStatus, setAddonStatus] = useState(0);
  const [OverallVehicle, setOverallVehicle] = useState();
  const [VehicleAddonData, setVehicleAddonData] = useState();
  const [hasRendered, setHasRendered] = useState(false);
  const [AddonData, setAddonData] = useState([]);

  const AddonModelOpen = () => {
    setVehicleAddonData([])
    setHasRendered(false);
    if (AddonStatus == 0) {
      OverallVehicle.filter(function (option) {
        if (option.id == vehicle_type) {
          setVehicleAddonData(option)
        }
      })
      setHasRendered(true);
      setAddonStatus(1)
      setTimeout(() => {
        $('#AddonDataModel').click();
      }, 1000);
    } else {
      setAddonStatus(0)
      setAddonData([])
    }
  }

  const AddonModelEdit = () => {
    setVehicleAddonData([])
    setHasRendered(false);
    OverallVehicle.filter(function (option) {
      if (option.id == vehicle_type) {
        setVehicleAddonData(option)
      }
    })
    setHasRendered(true);
    setTimeout(() => {
      $('#AddonDataModel').click();
    }, 1000);
  }

  const getAddon = async (data) => {
    const addonData = await data;
    if(addonData != 'close'){
      setAddonData([])
      if (addonData.length > 0) {
        setAddonStatus(1)
      } else {
        setAddonStatus(0)
      }
      setAddonData(addonData)
    }
    setHasRendered(false);
  }

  // console.log(AddonData, '===> AddonData')

  // pickup
  const fromLoad = (autocomplete) => {
    setFromPlaces(autocomplete)
  }

  const onFromPlaceChanged = async () => {
    if (from_places !== null) {
      var pickLat = from_places.getPlace().geometry.location.lat()
      var pickLon = from_places.getPlace().geometry.location.lng()
      var pickAdd = from_places.getPlace().formatted_address
      var pickName = from_places.getPlace().name

      setFromLat(pickLat)
      setFromLon(pickLon)
      setPickupLocation(pickAdd)
      // local storage
      localStorage.setItem('pick_up', pickName);
      const PickPosition = {
        lat: pickLat,
        lng: pickLon
      }
      localStorage.setItem('pickupPosition', JSON.stringify(PickPosition))
      window.dispatchEvent(new Event("storage"));
    }
  }

  // drop
  const toLoad = (autocomplete) => {
    setToPlaces(autocomplete)
  }

  const onToPlaceChanged = async () => {
    if (to_places !== null) {
      var dropLat = to_places.getPlace().geometry.location.lat()
      var dropLon = to_places.getPlace().geometry.location.lng()
      var dropAdd = to_places.getPlace().formatted_address
      var dropName = to_places.getPlace().name

      setToLat(dropLat)
      setToLon(dropLon)
      setDropLocation(dropAdd)
      // local storage
      localStorage.setItem('drop', dropName);
      const DropPosition = {
        lat: dropLat,
        lng: dropLon
      }
      localStorage.setItem('dropoffPosition', JSON.stringify(DropPosition))
      window.dispatchEvent(new Event("storage"));
    }
  }

  const Selectstyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '25px',
    }),
  };

  const selectChange = (event) => {
    if (event.name == 'main_category') {
      setMainCategory(event.value)
    } else if (event.name == 'sub_category') {
      setSubCategory(event.value)
    } else if (event.name == 'vehicle_type') {
      setVehicleType(event.value)
      setVehicleName(event.text)
      // addon old hide
      setAddonData([]);
      setHasRendered(false);
      setAddonStatus(0);
      setAddonShow(0);
      setHoursShow(1)
      
      OverallVehicle.filter(function (option) {
        // no of hours
        if(option.id == event.value){
          setMaxHours(option.max_hours)
          setMinHours(option.min_hours)
          sethrs(option.min_hours)
        }
        // addon check
        if (option.id == event.value && option.addon_services.length > 0) {
          setAddonShow(1);
        }
      })
    }
  }

  const inputChange = (event) => {
    let { name, value } = event.target;
    if (name == 'short_name') {
      setShortName(value)
    } else if (name == 'main_name') {
      setMainName(value)
    } else if (name == 'cc') {
      setCc(value)
    } else if (name == 'phone') {
      setPhone(value)
    } else if (name == 'email') {
      setEmail(value)
    } else {
      setComment(value)
    }
  }

  const FormValidation = () => {
    let isValid = true;
    setFormError({})

    if (pickup_location.trim() == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'pickup': 'Enter Pickup Location' }))
    }
    if (drop_location.trim() == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'drop': 'Enter Drop Location' }))
    }
    // if (main_category == "") {
    //   isValid = false;
    //   setFormError(prevError => ({ ...prevError, 'mainc': 'Select Main Category' }))
    // }
    // if (sub_category == "") {
    //   isValid = false;
    //   setFormError(prevError => ({ ...prevError, 'sub': 'Select Sub Category' }))
    // }
    if (vehicle_type == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'vehicle': 'Select Vehicle Type' }))
    }
    if (ChrDate == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'date': 'Select Date' }))
    }
    if (ChrTime == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'time': 'Select Time' }))
    }
    if (hrs == "") {
      isValid = false;
      setFormError(prevError => ({ ...prevError, 'hour': 'Select No.of Hours' }))
    }
    // if (short_name.trim() == "") {
    //   isValid = false;
    //   setFormError(prevError => ({ ...prevError, 'short': 'Enter Short Name' }))
    // }
    // if (main_name.trim() == "") {
    //   isValid = false;
    //   setFormError(prevError => ({ ...prevError, 'main': 'Enter Passenger Name' }))
    // }
    // if (cc.trim() == "") {
    //   isValid = false;
    //   setFormError(prevError => ({ ...prevError, 'cc': 'Select CC' }))
    // }
    // if (phone.trim() == "") {
    //   isValid = false;
    //   setFormError(prevError => ({ ...prevError, 'phone': 'Enter Phone number' }))
    // }

    return isValid;
  }

  function rideTime(date, time) {
    var changeDate = moment(date).format('DD/MM/YYYY') + ' ' + time;
    var flightArrivalTime = new Date(changeDate)
    var calTime = 5 * 60000;
    var pick_upTime = new Date(flightArrivalTime.getTime() - calTime)
    return moment(pick_upTime).format('YYYY-MM-DD HH:mm')
  }

  //booking
  async function CharterNow(event) {
    event.preventDefault();
    setCartButton(1)
    if (FormValidation()) {
      const Values = await fareAPICall()
      if (Values.message == "success") {
        // let ride_time = rideTime(moment(ChrDate).format('YYYY-MM-DD'), ChrTime)
        var surge = JSON.stringify(Values.result.surgeprice)
        store.dispatch({
          type: "addToCart",
          payload: {
            pick_up: pickup_location,
            pickup_lat: from_lat,
            pickup_lon: from_lon,
            drop_location: drop_location,
            no_of_adults: MaxPassenger,
            luggage: MaxLuggage,
            drop_lat: to_lat,
            drop_lon: to_lon,
            customer_id: "",
            mode: "ridelater",
            rider_type: 3,
            user_type: 3,
            trip_type: 3,
            request_type: "web",
            service_type: "charter",
            ride_date: moment(ChrDate).format('YYYY-MM-DD'),
            // ride_time: ride_time,
            no_of_hours: hrs,
            main_vehicle_id: main_category,
            sub_vehicle_id: sub_category,
            vehicle_id: vehicle_type,
            vehicle_type: vehicle_name,
            date: moment(ChrDate).format('YYYY-MM-DD'),
            today_date: moment(ChrDate).format('YYYY-MM-DD'),
            pick_up_time: ChrTime,
            short_name: "",
            passenger_name: main_name,
            passenger_phone_code: cc,
            passenger_phone_number: cc + '' + phone,
            passenger_email: email,
            promocode: "",
            special_note: comment,
            type: "Charter",
            vehicle_typeName: vehicle_name,
            total_amount: parseFloat(Values.result.amount).toFixed(2),
            total_distance: Values.result.total_distance,
            total_minute: Values.result.total_minute,
            extra_stops: Values.result.extra_stops,
            extra_stop_status: Values.result.extra_stop_status,
            per_stop_fare: Values.result.per_stop_price,
            per_stop_increase_cap_fare: Values.result.per_stop_increase_price,
            per_stop_multiple: Values.result.per_stop_multiple,
            per_stop_increase_multiple: Values.result.per_stop_increase_multiple,
            overall_extra_stop_cost: Values.result.overall_extra_stop_cost,
            minimum_distance_fare: Values.result.minimum_fare,
            minimum_distance_fare_status: Values.result.minimum_fare_status,
            sub_total: Values.result.sub_total,
            cap_price: Values.result.cap_price,
            cap_status: Values.result.capped_status,
            base_fare: Values.result.base_fare,
            surge_price: Values.result.surgeprice.length > 0 ? surge : '[]',
            addon_amount: Values.result.addon_amount,
            addon_data: Values.result.addon_data.length > 0 ? Values.result.addon_data : '',
            insurance: insurance,
            gst : Values.result.gst
          }
        });

        toast.success('Booking added into cart', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        toast.error(Values.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCartButton(0)
        return false
      }
    } else {
      setCartButton(0)
      return false
    }
  }

  const getCheck = async (token) => {
    const tokenMessage = await token;
    if (tokenMessage == 'sucessEmail') {
      setEmailVerify(1)
      toast.success('OTP verified successfully', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    } else if (tokenMessage == 'sucessMobile') {
      setPhoneVerify(1)
      toast.success('OTP verified successfully', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  async function CountryCodeApi() {
    let data = {}
    await ApiCall('countrycode', data).then((data) => {
      if (data.success === "success") {
        setCountryCode(data.data)
      }
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    setSubOption([
      { value: '', name: 'sub_category', label: <div>Sub Category</div> },
    ])
    SubApiCall()
  }, [main_category])

  const SubApiCall = async () => {
    let data = {
      category_id: main_category
    };

    await ApiCall('sub_categories', data).then((data) => {
      if (data.message === "category listed successfully.") {
        const subData = data.result;
        if (subData.length > 0) {
          const newSubOptions = subData.map((data, i) => ({
            value: data.id,
            name: 'sub_category',
            label: (
              <div>
                <img src={ApiKey.main_name + '/' + data.image} className="option-image" />
                {data.subcategory_name}
              </div>
            ),
          }));

          setSubOption((prevSubOptions) => [...prevSubOptions, ...newSubOptions]);
        }
      }
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    setVehicleOption([
      { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
    ])
    VehiApiCall()
  }, [sub_category])

  const VehiApiCall = async () => {
    let data = {
      subcategory_id: sub_category
    };
    await ApiCall('vehicleCategory', data).then((data) => {
      if (data.message === "category listed successfully.") {
        const vechData = data.result;
        if (vechData.length > 0) {
          const newVechOptions = vechData.map((data, i) => ({
            value: data.id,
            name: 'vehicle_type',
            text: data.vehicle_type,
            label: (
              <div>
                <img src={ApiKey.main_name + '/' + data.image} className="option-vehimage" />
                {data.vehicle_type.split('_').join(" ")}
              </div>
            ),
          }));
          setVehicleOption((prevSubOptions) => [...prevSubOptions, ...newVechOptions])
        }
      }
    }).catch(err => console.log(err));
  }

  async function MainApi() {
    let data = {};
    await ApiCall('getMainCategoryGuest', data).then((data) => {
      if (data.success === "success") {
        const mainData = data.data;
        if (mainData.length > 0) {
          const newMainData = mainData.map((data, i) => ({
            value: data.id,
            name: 'main_category',
            label: (
              <div>
                <img src={ApiKey.main_name + '/' + data.image} className="option-image" />
                {data.category_name}
              </div>
            ),
          }));

          setMainOption((prevSubOptions) => [...prevSubOptions, ...newMainData]);
        }
      } else {
        toast.error('Main category not found.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }).catch(err => console.log(err));
  }

  async function MainTextApi() {
    const searchParams = new URLSearchParams(window.location.search);
    const vehicle_name = searchParams.get('type');
    let data = {
      vehicle_name: vehicle_name
    }
    await ApiCall('getMainCategoryId', data).then((data) => {
      if (data.success === "success") {
        setMainCategory(data.data)
        setMainDisable(1)
        setTimeout(() => {
          SubApiCall()
        }, 2000);
      } else {
        toast.error('Main category not found.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }).catch(err => console.log(err));
  }

  const fareEstiamte = async () => {
    if (pickup_location != '' && drop_location != '' && ChrDate != '' && ChrTime != '' && vehicle_type != '' && hrs != '') {
      const Values = await fareAPICall()
      if (Values.message == "success") {
        setFareData(Values.result)
      } else {
        toast.error(Values.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }

  const fareAPICall = async () => {
    let data = {
      customer_id: "",
      pickup_lat: from_lat,
      pickup_lon: from_lon,
      drop_lat: to_lat,
      drop_lon: to_lon,
      vehicle_id: vehicle_type,
      service_type: "charter",
      ride_date: moment(ChrDate).format('YYYY-MM-DD'),
      ride_time: ChrTime,
      promocode: "",
      no_of_hours: hrs,
      date: moment(ChrDate).format('YYYY-MM-DD'),
      pick_up_time: ChrTime,
      guest_type: 1,
      addon_services: AddonData.length > 0 ? JSON.stringify(AddonData) : '',
      insurance: insurance == true ? 1 : 0,
    }
    var FareData = {};
    await ApiCall('guest_fare_estimate', data).then((data) => {
      FareData = data;
    }).catch(err => console.log(err));
    return FareData
  }

  async function VehicleApi() {
    let data = {
      service_type: 'charter',
      no_of_seats: MaxPassenger,
      luggage: MaxLuggage
    };
    await ApiCall('getVehicleCategoryGuest', data).then((data) => {
      if (data.success === "success") {
        const vechData = data.data;
        setVehicleOption([
          { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
        ])
        setOverallVehicle("");
        if (vechData.length > 0) {
          const newVechOptions = vechData.map((data, i) => ({
            value: data.id,
            name: 'vehicle_type',
            text: data.vehicle_type,
            label: (
              <div>
                <img src={ApiKey.main_name + '/' + data.image} className="option-vehimage" />
                {data.vehicle_type.split('_').join(" ")}
              </div>
            ),
          }));
          setVehicleOption((prevSubOptions) => [...prevSubOptions, ...newVechOptions])
          setvehicleshow(1);
          setOverallVehicle(vechData)
        }
      } else {
        toast.error('Vehicle category not found.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }).catch(err => console.log(err));
  }

  async function VehicleTextApi() {
    const searchParams = new URLSearchParams(window.location.search);
    const vehicle_name = searchParams.get('type');
    let data = {
      vehicle_name: vehicle_name
    }
    await ApiCall('getVehicleId', data).then((data) => {
      if (data.success === "success") {
        setVehicleType(data.data.id)
        setVehicleName(data.data.vehicle_type)
        setMainDisable(1)
        setTimeout(() => {
          SubApiCall()
        }, 2000);
      } else {
        toast.error('Main category not found.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }).catch(err => console.log(err));
  }

  async function VehicleGroupApi() {
    const searchParams = new URLSearchParams(window.location.search);
    const groupName = searchParams.get('type');
    let data = {
      group_name: groupName,
      no_of_seats: MaxPassenger,
      luggage: MaxLuggage,
      service_type: 'charter',
    }
    await ApiCall('vehicleCategoryGroupList', data).then((data) => {
      if (data.message === "success") {
        const vechGrpData = data.result;
        setOverallVehicle("");
        setVehicleOption([
          { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
        ])
        if (vechGrpData.length > 0) {
          const newVechGrpOptions = vechGrpData.map((data, i) => ({
            value: data.id,
            name: 'vehicle_type',
            text: data.vehicle_type,
            label: (
              <div>
                <img src={ApiKey.main_name + '/' + data.image} className="option-vehimage" />
                {data.vehicle_type.split('_').join(" ")}
              </div>
            ),
          }));
          setVehicleOption((prevSubOptions) => [...prevSubOptions, ...newVechGrpOptions])
          setOverallVehicle(vechGrpData)
          setvehicleshow(1)
        }
      } else {
        // VehicleApi()
        toast.error("No vehicle matches your requirement", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
      });
      }
    }).catch(err => console.log(err));
  }

  const MaxChooseVeh = () => {
    setVehicleType("");
    setVehicleName("")
    setVehicleOption([
      { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
    ])
    const searchParams = new URLSearchParams(window.location.search);
    const vehicle_name = searchParams.get('type');
    if (vehicle_name == null) {
      VehicleApi()
    } else if (vehicle_name != 'all') {
      VehicleGroupApi()
    } else {
      VehicleApi()
    }
  }

  useEffect(() => {
    // setvehicleshow(0)
    MaxChooseVeh()
  }, [MaxLuggage, MaxPassenger])

  const [insurance, setInsurance] = React.useState(false);
  const [insCancelHour,setInsCancelHour] = React.useState(0);
  const [insCancelAmount,setInsCancelAmount] = React.useState(0);

  function handleChangeInsurance(e){
    setInsurance(e.target.checked);
}
  useEffect(() => {
    let insurance_cancellation_hour = localStorage.getItem('cancel_hour');
    let insurance_amount = localStorage.getItem('insurance_amount');
    setInsCancelHour(insurance_cancellation_hour);
    setInsCancelAmount(insurance_amount);
},[]);

  useEffect(() => {
    setTimeout(() => {
      fareEstiamte()
    }, 1000);
  }, [pickup_location, drop_location, ChrDate, ChrTime, main_category, sub_category, vehicle_type, hrs, AddonData,insurance])

  useEffect(() => {
    // const searchParams = new URLSearchParams(window.location.search);
    // const vehicle_name = searchParams.get('type');
    CountryCodeApi()
    // if (vehicle_name == null) {
    //   // MainApi()
    //   VehicleApi()
    // } else if (vehicle_name != 'all') {
    //   // MainTextApi()
    //   VehicleTextApi()
    //   setTimeout(() => {
    //     // MainApi()
    //     VehicleApi()
    //   }, 3000);
    // } else {
    //   // MainApi()
    //   VehicleApi()
    // }
    //clear localstorage
    localStorage.removeItem('stopPosition0')
    localStorage.removeItem('stopPosition1')
    localStorage.removeItem('pick_up')
    localStorage.removeItem('pickupPosition')
    localStorage.removeItem('drop')
    localStorage.removeItem('dropoffPosition')
    window.dispatchEvent(new Event("storage"));
  }, [])


  return (
    <>
      <div className='formDiv' id="charterBook">
        <form autoComplete='off'>

          <div className='row'>
            <div className='col-lg-6 col-md-12 col-12 mt-1'>
              <h5 className=''>Booking Details</h5>
              {/* <hr /> */}
              <div className='row mb-1'>
                {/* <p>Pick Date/Time</p> */}
                <div className='col-lg-6 col-12 mt-1'>
                  <div className="inner-addon right-addon">
                    <i className="glyphicon glyphicon-user fa fa-calendar textColor"></i>
                    {/* <input type="text" className="form-control rounded-pill" placeholder='Date'/> */}
                    <DatePicker
                      selected={ChrDate}
                      onChange={onDateChange}
                      dateFormat='yyyy-MM-dd'
                      minDate={new Date}
                      className='form-control'
                      placeholderText='Date'
                    />
                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.date}</span>
                  </div>
                </div>
                <div className='col-lg-6 col-12 mt-1'>
                  <div className="inner-addon right-addon">
                    <i className="glyphicon glyphicon-user fa fa-clock textColor"></i>
                    {/* <input type="text" className="form-control rounded-pill" placeholder='Time'/> */}
                    <Datetime dateFormat={false}
                      inputProps={timeProps}
                      timeConstraints={timeConstraints}
                      onChange={onTimeChange}
                      value={ChrTime}
                    />
                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.time}</span>
                  </div>
                </div>
              </div>

              <div className="mb-1">
                {/* <label className="form-label">Pickup Location</label> */}
                <Autocomplete onLoad={fromLoad} onPlaceChanged={onFromPlaceChanged} options={AutocompleteRestrict}>
                  <input type="text" className="form-control rounded-pill" id="pickup" name="pickup" placeholder='Pickup Location' />
                </Autocomplete>
                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.pickup}</span>
              </div>

              <div className="mb-1">
                {/* <label className="form-label">Drop Location</label> */}
                <Autocomplete onLoad={toLoad} onPlaceChanged={onToPlaceChanged} options={AutocompleteRestrict}>
                  <input type="text" className="form-control rounded-pill" id="drop" name="drop" placeholder='Drop Location' />
                </Autocomplete>
                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.drop}</span>
              </div>

              {/* <div className='row'>
            <p>Choose your Vehicle</p>
            <div className='col-12'>
              <div className="mb-3">
                <Select
                  styles={Selectstyles}
                  options={main_option}
                  placeholder="Main Category"
                  onChange={selectChange}
                  isDisabled={main_disable == 1 ? true : false}
                  value={main_option.filter(function (option) {
                    return option.value === main_category;
                  })}
                />
                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.mainc}</span>
              </div>
            </div>
            <div className='col-12'>
              <div className="mb-3">
                <Select
                  styles={Selectstyles}
                  options={sub_option}
                  placeholder="Sub Category"
                  onChange={selectChange}
                />
                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.sub}</span>
              </div>
            </div>
          </div> */}

              <div className="passenger-dtls mb-3">
                <div className="row mb-1">
                  <div className="col-lg-4 col-6">
                    <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>Passenger <i class="fa-solid fa-circle-info" data-tooltip-id="tooltip-passenger"></i></span>
                    <ReactTooltip id="tooltip-passenger" place="top">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Include all ages,</span>
                        <span>exclude wheelchair user in wheelchair</span>
                      </div>
                    </ReactTooltip>
                    <div className="buttons d-flex align-items-center">
                      <p className='airport-decrement-btn' id="DecP" onClick={maxpassengerOut}> &nbsp; - &nbsp;</p>
                      <span className="number_padd" id="passenger-input">{MaxPassenger}</span>
                      <p className='airport-increment-btn' id="DecP" onClick={maxpassengerIn}> &nbsp; + &nbsp;</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-6">
                    <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>Luggage <i class="fa-solid fa-circle-info" data-tooltip-id="tooltip-luggage"></i></span>
                    <ReactTooltip id="tooltip-luggage" place="top">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>The standard size is 24 and above.</span>
                        <span>If greater than 24, please count as 2</span>
                      </div>
                    </ReactTooltip>
                    <div className="buttons d-flex align-items-center">
                      <p className='airport-decrement-btn' id="DecP" onClick={maxluggageOut}> &nbsp; - &nbsp;</p>
                      <span className="number_padd" id="wheelchair-input">{MaxLuggage}</span>
                      <p className='airport-increment-btn' id="DecP" onClick={maxluggageIn}> &nbsp; + &nbsp;</p>
                    </div>
                  </div>

                  {/* <div className='col-lg-4 col-12'>
                    <label className="form-label" style={{ fontSize: '1px' }}>&nbsp;</label>
                    <input type="button" className="form-control btn rounded-pill billCart" value='Choose Your Vehicle' onClick={MaxChooseVeh} />
                  </div> */}
                </div>

              </div>

              <div className="mb-1">
                {/* <label className="form-label">Choose Vehicle Type</label> */}
                <Select
                  styles={Selectstyles}
                  options={vehicle_option}
                  placeholder="Vehicle Type"
                  onChange={selectChange}
                  // isDisabled={main_disable == 1 ? true : false}
                  // isDisabled={vehicleshow == 1 ? false : true}
                  value={vehicle_option.filter(function (option) {
                    return option.value === vehicle_type;
                  })}
                />
                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.vehicle}</span>
              </div>

              <div className="passenger-dtls">
                <div className="row">
                  <div className={AddonShow == 1 ? 'col-lg-4 col-12' : 'col-lg-6 col-12 d-none'}>
                    {/* <span className="no_of" style={{ color: 'white' }}>&nbsp;</span> */}
                    <div class="form-check d-flex justify-content-between">
                      <div className=''>
                        <input class="form-check-input" type="checkbox" id="addon" name="addon" onChange={AddonModelOpen} checked={AddonData != '' ? true : false} />
                        <label class="form-check-label">
                          Add-on Services
                        </label>
                        <i className='fa fa-circle-info ms-1' data-tooltip-id="tooltip-addon"></i>
                      </div>
                      <p className={AddonData.length > 0 ? 'text-end addon-edit' : 'text-end addon-edit d-none'} onClick={AddonModelEdit}>Edit</p>

                      <ReactTooltip id="tooltip-addon" place="top">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {AddonData.length > 0 ? AddonData.map((item, key) => {
                            return (
                              <span>{item.name} - {item.count}</span>
                            )
                          }) : <><p>No add-on selected</p></>}
                        </div>
                      </ReactTooltip>
                    </div>
                  </div>
                  <div className={HoursShow == 1 ? 'col-lg-4 col-12' : 'd-none'}>
                    <span className="no_of" style={{ color: 'white' }}>No of Hours({MinHours}-{MaxHours}hrs)</span>
                    <div className="buttons d-flex align-items-center">
                      <p className='airport-decrement-btn' id="DecP" onClick={HrsOut}> &nbsp; - &nbsp;</p>
                      <span className="number_padd" id="passenger-input">{hrs}</span>
                      <p className='airport-increment-btn' id="DecP" onClick={HrsIn}> &nbsp; + &nbsp;</p>
                    </div>
                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.hour}</span>
                  </div>
                  <div className='col-lg-4 col-12'>
                      <input className="form-check-input" type='checkbox' id='insurance' name = 'insurance' onChange={handleChangeInsurance}/>
                      <label className="form-check-label ms-1 me-1">Insurance</label>
                      <i className='fa fa-circle-info ms-1' data-tooltip-id="tooltip-insurance"></i>
                      <ReactTooltip id="tooltip-insurance" place="top">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>Insurance allows free cancellation up to {insCancelHour} hours from the service time.</span>
                            <span>For a small fee, it gives you a greater flexibility!</span>
                        </div>
                      </ReactTooltip>
                  </div>
                </div>
              </div>

              {/* <div className="text-end mb-3">
            <input type="button" className="btn rounded-pill billCart" value='Check Fare' onClick={fareEstiamte} />
          </div> */}

            </div>
            <div className='col-lg-6 col-md-12 col-12 mt-1'>
              <div className='container'>
                <h5>&nbsp;</h5>
                <div className="confirnBook mb-1">
                  <p className="text-center confirm-title pt-2">Fare Breakdown</p>

                  <div className='row justify-content-center'>
                    <div className="col-md-11 col-11 pt-1">
                      <div className='row'>
                        <div className={fareData != '' && (fareData.surgeprice.length > 0 || fareData.addon_data.length > 0) ? 'col-lg-7 col-12 checkout p-1' : 'd-none'}>
                          <div className="container surge_con" id='fare-scroll'>
                            <table id="fare-table">
                              {fareData != '' ? <>
                                {fareData.surgeprice.length > 0 ? fareData.surgeprice.map((data, i) => {
                                  return (<><tr>
                                    <td style={{ fontSize: '14px' }}>{data.name}</td>
                                    <td>:</td>
                                    <td>
                                      <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.surge_amount).toFixed(2)}</p>
                                      {/* <p className='m-0 p-0 dist-charge'><span>S$ </span> {data.price}</p> */}
                                    </td>
                                  </tr>
                                    <tr>
                                      <td colSpan={3}><hr /></td>
                                    </tr></>)
                                }) : <></>}</> : <></>
                              }
                              {fareData != '' ? <>
                                {fareData.addon_data.length > 0 ? fareData.addon_data.map((data, i) => {
                                  return (<><tr>
                                    <td style={{ fontSize: '14px' }}>{data.name}</td>
                                    <td>:</td>
                                    <td>
                                      <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                    </td>
                                  </tr>
                                    <tr>
                                      <td colSpan={3}><hr /></td>
                                    </tr></>)
                                }) : <></>}</> : <></>
                              }
                            </table>
                          </div>
                        </div>
                        <div className={fareData != '' && (fareData.surgeprice.length > 0 || fareData.addon_data.length > 0) ? 'col-lg-5 col-12 py-2' : 'col-md-12 py-2'}>
                          <div className="row">
                            <div className={fareData != '' ? (fareData.insurance_amount != 0 && fareData.gst != 0 ? "col-md-4 text-center dist-fare" : (fareData.insurance_amount != 0 || fareData.gst != 0 ? "col-md-6 text-center dist-fare" : "col-md-12 text-center ")) : "col-md-12 text-center "}>
                              <p className="dist-fare-head m-0">Duration Fare</p>
                              <p className="m-0 dist-charge" id="m-fare-duration"> <span>S$</span> {fareData != '' ? parseFloat(fareData.duration_price).toFixed(2) : parseFloat(0).toFixed(2)}</p>
                              <p className="m-0 dist-time" id="m-fare-duration-text">{fareData != '' ? fareData.total_duration : '0 hour(s)'}</p>
                            </div>
                            <div className={fareData != '' && fareData.insurance_amount != 0 ? fareData.gst != 0 ? 'col-md-4 text-center dist-fare' : 'col-md-4 text-center' : 'd-none'}>
                                <p className="dist-fare-head m-0">Insurance Fare</p>
                                <p className="m-0 dist-charge" id="m-fare-duration"> <span>S$</span> {parseFloat(fareData.insurance_amount).toFixed(2)}</p>
                            </div>
                            {fareData != '' && fareData.gst != 0 ? 
                            <div className={fareData != '' && fareData.gst != 0 && fareData.insurance_amount != 0 ? "col-md-4 text-center" : "col-md-6 text-center"}>
                                <p className="dist-fare-head m-0">GST</p>
                                <p className="m-0 dist-charge" id="m-fare-duration"> <span>S$</span> {fareData != '' ? parseFloat(fareData.gst).toFixed(2) : parseFloat(0).toFixed(2)}</p>
                            </div> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row justify-content-center" id="dur-div">
                    <div className="col-md-12 py-2">
                      <div className="row">
                        <div className="col-12 text-center">
                          <p className="dist-fare-head m-0">Duration Fare</p>
                          <p className="m-0 dist-charge" id="m-fare-duration"> <span>S$</span> {fareData != '' ? parseFloat(fareData.duration_price).toFixed(2) : parseFloat(0).toFixed(2)}</p>
                          <p className="m-0 dist-time" id="m-fare-duration-text">{fareData != '' ? fareData.total_duration : '0 hour(s)'}</p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="row justify-content-center">
                  <div className="col-10">
                    <hr className="fare-hr" />
                  </div>
                </div> */}

                  <div className="row justify-content-center">
                    <div className="col-md-10 col-12 py-2">
                      <div className='d-flex justify-content-center fare-total'>Total Price : S$&nbsp;&nbsp; {fareData != '' ? parseFloat(fareData.amount).toFixed(2) : parseFloat(0).toFixed(2)}</div>
                      {/* <div className="row">
                  <div className="col-5 fare-total p-0">
                    Total Price
                  </div>
                  <div className="col-1 fare-total">
                    :
                  </div>
                  <div className="col-6 fare-list1 p-0">
                    <span id="ma-fare-total">{fareData != '' ? parseFloat(Math.ceil(fareData.amount)).toFixed(2) : parseFloat(0).toFixed(2)} </span>&nbsp;&nbsp;SGD
                  </div>
                </div> */}
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3 comment">
                  <textarea className="form-control" rows="3" placeholder="Comments if anything" name="comment" onChange={inputChange}></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="text-end">
            {cart_button == 1 ? <div className='d-flex justify-content-end'><ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#ff8901"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            /></div> :
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="submit" className="btn rounded-pill billCart" onClick={CharterNow}>Add Cart</button>
                {cartData.length > 0 ? <input type="button" className="btn rounded-pill billCart" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-backdrop="false" value={"View Cart (" + cartData.length + ")"} /> : ''}
              </div>}
          </div>

        </form>
      </div>

      {/* verify button */}
      <VerifyModel email={email} phone={cc + '' + phone} getSuccess={getCheck} />
      <div className='d-none'>
        <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#VerifyEmail" id="verifyEmail" value='' />
      </div>
      <div className='d-none'>
        <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#VerifyPhone" id="verifyPhone" value='' />
      </div>

      {/* model for fare estimate */}
      <div>
        <div className='d-none'>
          <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#estimateModal" id="EstimateModal" data-bs-backdrop="false" />
        </div>
        <div className="modal fade" id="estimateModal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="false">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content confirnBook">
              <div className="d-flex justify-content-end confirnClose">
                <i className="fa fa-times-circle" data-bs-dismiss="modal" aria-label="Close"></i>
              </div>
              <p className="text-center confirm-title">Fare Breakdown</p>
              <div className="row justify-content-center mt-4">
                <div className="col-10">
                  <div className="d-flex">
                    <div className="pick-circle"></div>
                    <p className="from-locat">From Location</p>
                  </div>
                  <p className="from-addr" id="ma-fare_pick">{pickup_location}</p>

                  <hr />

                  <div className="d-flex">
                    <div className="drop-circle"></div>
                    <p className="to-locat">To Location</p>
                  </div>
                  <p className="to-addr" id="ma-fare_drop">{drop_location}</p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-10 col-11 px-4 pt-3 fare-head">
                  <p id="ma-fare-category">{vehicle_name}</p>
                </div>
              </div>

              <div className="row justify-content-center" id="dur-div">
                <div className="col-md-12 px-4 py-4">
                  <div className="row">
                    <div className="col-12 text-center">
                      <p className="dist-fare-head">Duration Fare</p>
                      <p className="mb-0 dist-charge" id="m-fare-duration"> {fareData != '' ? parseFloat(fareData.duration_price).toFixed(2) : ''} <span>SGD</span></p>
                      <p className="mb-0 dist-time" id="m-fare-duration-text">{fareData != '' ? fareData.total_duration : ''}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-10">
                  <hr className="fare-hr" />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-10 col-12 px-4 py-4">
                  <div className='d-flex justify-content-center fare-total'>Total Price : <span id="ma-fare-total">{fareData != '' ? parseFloat(fareData.amount).toFixed(2) : ''} </span>&nbsp;&nbsp;SGD</div>
                  {/* <div className="row">
                    <div className="col-5 fare-total p-0">
                      Total Price
                    </div>
                    <div className="col-1 fare-total">
                      :
                    </div>
                    <div className="col-6 fare-list1 p-0">
                      <span id="ma-fare-total">{fareData != '' ? parseFloat(Math.ceil(fareData.amount)).toFixed(2) : ''} </span>&nbsp;&nbsp;SGD
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-10 mt-3">
                  <hr className="fare-hr" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* add-on model button */}
      <div className='d-none'>
        <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#AddonData" data-bs-backdrop="false" id="AddonDataModel" />
      </div>
      {!hasRendered ? <></> : <AddonModel AddonData={VehicleAddonData} SelectAddonData={AddonData} getAddon={getAddon} />}
    </>
  )
}

export default CharterBook