import { Autocomplete, LoadScript } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import store from '../redux/Store';
import moment from 'moment';
import Select from 'react-select';
import { ApiCall, ApiKey } from '../common/Apikey';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import $ from 'jquery';
import VerifyModel from './VerifyModel';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from "react-tooltip";
import AddonModel from './AddonModel';

function ScheduleBook() {

    const AutocompleteRestrict = {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: ["sg"] },  //, "my"
    };

    const cartData = useSelector((state) => state.cart.AddCart);

    let timeConstraints = {
        minutes: {
            step: 5
        }
    }

    // addon
    const [AddonShow, setAddonShow] = useState(0);
    const [AddonStatus, setAddonStatus] = useState(0);
    const [ReAddonStatus, setReAddonStatus] = useState(0);
    const [OverallVehicleSch, setOverallVehicleSch] = useState();
    const [OverallVehicleGeo, setOverallVehicleGeo] = useState();
    const [VehicleAddonData, setVehicleAddonData] = useState();
    const [hasRendered, setHasRendered] = useState(false);
    const [AddonData, setAddonData] = useState([]);
    const [ReAddonData, setReAddonData] = useState([]);
    const [AddonTripType, setAddonTripType] = useState('from');

    const AddonModelOpen = () => {
        setVehicleAddonData([])
        setHasRendered(false);
        setAddonTripType('from')
        if (AddonStatus == 0) {
            OverallVehicleSch.filter(function (option) {
                if (option.id == vehicle_type) {
                    setVehicleAddonData(option)
                }
            })
            setHasRendered(true);
            setAddonStatus(1)
            setTimeout(() => {
                $('#AddonDataModel').click();
            }, 1000);
        } else {
            setAddonStatus(0)
            setAddonData([])
        }
    }

    const AddonModelEdit = () => {
        setVehicleAddonData([])
        setHasRendered(false);
        setAddonTripType('from')
        OverallVehicleSch.filter(function (option) {
            if (option.id == vehicle_type) {
                setVehicleAddonData(option)
            }
        })
        setHasRendered(true);
        setTimeout(() => {
            $('#AddonDataModel').click();
        }, 1000);
    }

    const AddonModelOpenRe = () => {
        setVehicleAddonData([])
        setHasRendered(false);
        setAddonTripType('return')
        if (ReAddonStatus == 0) {
            OverallVehicleSch.filter(function (option) {
                if (option.id == vehicle_type) {
                    setVehicleAddonData(option)
                }
            })
            setHasRendered(true);
            setReAddonStatus(1)
            setTimeout(() => {
                $('#AddonDataModel').click();
            }, 1000);
        } else {
            setReAddonStatus(0)
            setReAddonData([])
        }
    }

    const AddonModelEditRe = () => {
        setVehicleAddonData([])
        setHasRendered(false);
        setAddonTripType('return')
        OverallVehicleSch.filter(function (option) {
            if (option.id == vehicle_type) {
                setVehicleAddonData(option)
            }
        })
        setHasRendered(true);
        setTimeout(() => {
            $('#AddonDataModel').click();
        }, 1000);
    }

    const getAddon = async (data) => {
        const addonData = await data;
        if(addonData != 'close'){
            if (AddonTripType == 'from') {
                addonData.length > 0 ? setAddonStatus(1) : setAddonStatus(0);
                // setAddonData([])
                setAddonData(addonData)
            } else {
                addonData.length > 0 ? setReAddonStatus(1) : setReAddonStatus(0);
                // setReAddonData([])
                setReAddonData(addonData)
            }
        }
        setHasRendered(false);
    }

    // add-on for geo fence
    const [AddonList, setAddonList] = useState([]);
    const [MaxAddon, setMaxAddon] = useState(0);
    const [GeoAddonShow, setGeoAddonShow] = useState(0)
    const [cartItems, setCartItems] = useState([]);
    const [RecartItems, setReCartItems] = useState([]);

    const plus = (item) => {
        const existingItemIndex = cartItems.findIndex((cartItem) => cartItem.addon_id === item.addon_id);

        if (existingItemIndex !== -1) {
            const newCartItems = [...cartItems];

            // check max count
            const matchedItem = AddonList.find(item => item.id === newCartItems[existingItemIndex].addon_id);
            if (newCartItems[existingItemIndex].count == matchedItem.max_number) {
                toast.error('Maximum count reached.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                newCartItems[existingItemIndex].count += 1;
                setCartItems(newCartItems);
            }

        } else {
            if (cartItems.length == MaxAddon) {
                toast.error('Maximum Addon Services selected.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                setCartItems([...cartItems, { ...item, count: 1 }]);
            }
        }
    };

    const minus = (itemId) => {
        const newCartItems = cartItems.map((item) => {
            if (item.addon_id === itemId.addon_id) {
                const updatedCount = item.count >= 0 ? item.count - 1 : 0;
                return { ...item, count: updatedCount };
            } else {
                return item;
            }
        }).filter((item) => item.count > 0);

        setCartItems(newCartItems);
    };

    // geo fence return
    const plusre = (item) => {
        const existingItemIndex = RecartItems.findIndex((cartItem) => cartItem.addon_id === item.addon_id);

        if (existingItemIndex !== -1) {
            const newCartItems = [...RecartItems];

            // check max count
            const matchedItem = AddonList.find(item => item.id === newCartItems[existingItemIndex].addon_id);
            if (newCartItems[existingItemIndex].count == matchedItem.max_number) {
                toast.error('Maximum count reached.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                newCartItems[existingItemIndex].count += 1;
                setReCartItems(newCartItems);
            }

        } else {
            if (RecartItems.length == MaxAddon) {
                toast.error('Maximum Addon Services selected.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                setReCartItems([...RecartItems, { ...item, count: 1 }]);
            }
        }
    };

    const minusre = (itemId) => {
        const newCartItems = RecartItems.map((item) => {
            if (item.addon_id === itemId.addon_id) {
                const updatedCount = item.count >= 0 ? item.count - 1 : 0;
                return { ...item, count: updatedCount };
            } else {
                return item;
            }
        }).filter((item) => item.count > 0);

        setCartItems(newCartItems);
    };

    // max passenger and luggage
    const [MaxPassenger, setMaxPassenger] = useState(1);
    const [MaxLuggage, setMaxLuggage] = useState(0);
    const [vehicleshow, setvehicleshow] = useState(0);

    const maxpassengerIn = (event) => {
        let value = MaxPassenger;
        value++;
        setMaxPassenger(value)
    }

    const maxpassengerOut = (event) => {
        let value = MaxPassenger;
        if (value > 1) {
            value--
        }
        setMaxPassenger(value)
    }

    const maxluggageIn = (event) => {
        let value = MaxLuggage;
        value++;
        setMaxLuggage(value)
    }

    const maxluggageOut = (event) => {
        let value = MaxLuggage;
        if (value > 0) {
            value--
        }
        setMaxLuggage(value)
    }

    const [from_places, setFromPlaces] = useState("")
    const [from_lat, setFromLat] = useState("")
    const [from_lon, setFromLon] = useState("")
    const [pickup_location, setPickupLocation] = useState("")
    const [pick_name, setFromPlaceName] = useState("")
    const [to_places, setToPlaces] = useState("")
    const [to_lat, setToLat] = useState("")
    const [to_lon, setToLon] = useState("")
    const [drop_location, setDropLocation] = useState("")
    const [drop_name, setDropPlaceName] = useState("")
    const [main_category, setMainCategory] = useState("")
    const [sub_category, setSubCategory] = useState("")
    const [vehicle_type, setVehicleType] = useState("")
    const [email, setEmail] = useState("")
    const [main_name, setMainName] = useState("")
    const [cc, setCc] = useState("+65")
    const [phone, setPhone] = useState("")
    const [comment, setComment] = useState("")
    const [SchDate, setSchDate] = useState("")
    const [SchTime, setSchTime] = useState("")
    const [StopDivs, setStopDivs] = useState([]);
    const [formError, setFormError] = useState({ pickup: "", drop: "", vehicle: "", emailErr: "", main: "", cc: "", phone: "", date: "", time: "", rpickup: "", rdrop: "", rdate: "", rtime: "", dateAir: "", arrivalAir: "", flightAir: "", vehicleAir: "", dateAirRe: "", arrivalAirRe: "", flightAirRe: "", vehicleAirRe: "", stop1: "", stop2: "", rstop1: "", rstop2: "" })

    const [ReturnDiv, setReturnDiv] = useState(0)
    const [rfrom_places, setRFromPlaces] = useState("")
    const [rfrom_lat, setRFromLat] = useState("")
    const [rfrom_lon, setRFromLon] = useState("")
    const [rpickup_location, setRPickupLocation] = useState("")
    const [rto_places, setRToPlaces] = useState("")
    const [rto_lat, setRToLat] = useState("")
    const [rto_lon, setRToLon] = useState("")
    const [rdrop_location, setRDropLocation] = useState("")
    const [rcomment, setRComment] = useState("")
    const [rvehicle_type, setRVehicleType] = useState("")
    const [ReSchDate, setReSchDate] = useState("")
    const [ReSchTime, setReSchTime] = useState("")
    const [ReStopDivs, setReStopDivs] = useState([]);

    const [email_verify, setEmailVerify] = useState(0)
    const [phone_verify, setPhoneVerify] = useState(0)
    const [main_option, setMainOption] = useState([
        { value: '', name: "main_category", label: <div>Main Category</div> }
    ])
    const [sub_option, setSubOption] = useState([
        { value: '', name: 'sub_category', label: <div>Sub Category</div> },
    ])
    const [vehicle_option, setVehicleOption] = useState([
        { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
    ])
    const [country_code, setCountryCode] = useState([]);
    const [cart_button, setCartButton] = useState(0);
    const [main_disable, setMainDisable] = useState(0)

    const [mailverifybtn, setmailverifybtn] = useState(0)
    const [mobileverifybtn, setmobileverifybtn] = useState(0)

    //fare estinate 
    const [fareData, setFareData] = useState([])
    const [FareTotalAmount, setFareTotalAmount] = useState(0)

    const [refareData, setReFareData] = useState([])
    const [ReFareTotalAmount, setFareReTotalAmount] = useState(0)

    const [fareDataAir, setFareDataAir] = useState([])
    const [refareDataAir, setReFareDataAir] = useState([])

    const [geoFencetoast, setgeoFencetoast] = useState(0)
    const [geoFencetoastRe, setgeoFencetoastRe] = useState(0)

    // geo fenceing
    const [geo_fence, setGeoFence] = useState(0)
    const [geo_fence_return, setGeoFenceReturn] = useState(0)
    const [AirDate, setAirDate] = useState("")
    const [arrival_time, setArrivalTime] = useState("")
    const [flight_no, setFlightNo] = useState("")
    const [Passenger, setPassenger] = useState("1")
    const [Wheelchair, setWheelchair] = useState("0")
    const [Luggage, setLuggages] = useState("0")
    const [vehicle_typeArr, setVehicleTypeArr] = useState("")
    const [vehicle_name, setVehicleName] = useState("")
    const [flight_actual_arrival_time, setflight_actual_arrival_time] = useState("")
    const [flight_carrier_code, setflight_carrier_code] = useState("")
    const [flight_code, setflight_code] = useState("")
    const [ride_date, setride_date] = useState("")
    const [flight_date, setflight_date] = useState("")
    const [pick_up_time, setPickUpTime] = useState("")
    const [airport_check, setairport_check] = useState(0)
    const [changeflightDiv, setchangeflightDiv] = useState(0)
    const [flightDiv, setflightDiv] = useState(0)
    const [flight_data, setFlightData] = useState([])
    const [vehDiv, setvehDiv] = useState(0)
    const [Vehicleoptions, setVehicleoptions] = useState([
        { value: '', name: 'vehicle_typeAir', text: 'car', label: <div>Vehicle Type </div> }
    ])

    const [vehiclebtn, setVehicleBtn] = useState(0)
    const [vehiclebtnre, setVehicleBtnRe] = useState(0)

    const geoReturn = () => {
        setGeoFenceReturn(0)
    }

    const geoFrom = () => {
        setGeoFence(0)
    }

    const selectChangeAirr = (event) => {
        if (event.name == 'vehicle_typeAir') {
            setVehicleTypeArr(event.value)
            setVehicleName(event.text)
            // addon check
            setCartItems([])
            setGeoAddonShow(0)
            OverallVehicleGeo.filter(function (option) {
                if (option.id == event.value && option.addon_services.length > 0) {
                    setAddonList(option.addon_services)
                    setMaxAddon(option.maximum_count)
                    setGeoAddonShow(1)
                }
            })
        }
    }

    const onDateChangeAir = (event) => {
        setAirDate(event)
    }

    const inputChangeAir = (event) => {
        let { name, value } = event.target;
        if (name == 'arrival_time') {
            setArrivalTime(value)
        } else if (name == 'flight_number') {
            setFlightNo(value)
        }
    }

    const checkFlight = async () => {
        if (AirDate == "") {
            toast.error('Select date', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return false
        }

        if (flight_no.trim() == "") {
            toast.error('Enter flight number', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return false
        }

        let data = {
            date: moment(AirDate).format('YYYY-MM-DD'),
            flight_number: flight_no,
        }
        await ApiCall('getScheduledFlightDetails', data).then((data) => {
            if (data.message === "Flight details listed successfully") {
                setFlightData(data.result[0])

                var dataFgt = data.result[0]
                setflight_actual_arrival_time(dataFgt.FlightArrivalTime)
                setflight_carrier_code(dataFgt.carrierFsCode)
                setflight_code(dataFgt.flightNumber)
                setride_date(moment(dataFgt.FlightArrivalTime).format('YYYY-MM-DD'))
                setflight_date(moment(dataFgt.FlightArrivalTime).format('YYYY-MM-DD'))
                setairport_check(1)
                setflightDiv(1)
                setchangeflightDiv(1)
                // pick_up_time calculation
                var flightArrivalTime = new Date(dataFgt.FlightArrivalTime)
                var arrivalTime = parseInt(arrival_time, 10)
                var calTime = arrivalTime * 60000
                var pick_upTime = new Date(flightArrivalTime.getTime() + calTime)
                setPickUpTime(pick_upTime.toTimeString().slice(0, 8))
                ChooseVeh()
            } else {
                toast.error(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return false
            }
        }).catch(err => console.log(err));
    }

    // passenger
    const passengerIn = (event) => {
        let value = Passenger;
        value++;
        setPassenger(value)
    }

    const passengerOut = (event) => {
        let value = Passenger;
        if (value > 1) {
            value--
        }
        setPassenger(value)
    }

    // wheel chair
    const wheelchairIn = (event) => {
        let value = Wheelchair;
        value++;
        setWheelchair(value)
    }

    const wheelchairOut = (event) => {
        let value = Wheelchair;
        if (value > 0) {
            value--
        }
        setWheelchair(value)
    }
    // luggage
    const luggageIn = (event) => {
        let value = Luggage;
        value++;
        setLuggages(value)
    }

    const luggageOut = (event) => {
        let value = Luggage;
        if (value > 0) {
            value--
        }
        setLuggages(value)
    }

    const ChooseVeh = async () => {
        let data = {
            service_type: "airport",
            no_of_seats: Passenger,
            luggage: Luggage,
            wheel_chair: Wheelchair,
        }
        await ApiCall('getVehicleCategoryGuest', data).then((data) => {
            if (data.success === "success") {
                const vechData = data.data;
                setVehicleoptions([
                    { value: '', name: 'vehicle_typeAir', text: 'car', label: <div>Vehicle Type </div> }
                ])
                if (vechData.length > 0) {
                    const newVechOptions = vechData.map((data, i) => ({
                        value: data.id,
                        name: 'vehicle_typeAir',
                        text: data.vehicle_type,
                        label: (
                            <div>
                                <img src={ApiKey.main_name + '/' + data.image} className="option-vehimage" />
                                {data.vehicle_type.split('_').join(" ")}
                            </div>
                        ),
                    }));
                    setVehicleoptions((prevSubOptions) => [...prevSubOptions, ...newVechOptions])
                    setvehDiv(1)
                    setVehicleBtn(1)
                }
            } else {
                toast.error('Vehicle category not found.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return false
            }
        }).catch(err => console.log(err));
    }

    const fareAPICall = async () => {
        const inputdata = []
        var pick_up = {
            lat: from_lat,
            long: from_lon,
            stop_name: pickup_location,
        };
        inputdata.push(pick_up)
        StopDivs.map((data, i) => {
            inputdata.push(data)
        })
        var drop = {
            lat: to_lat,
            lon: to_lon,
            stop_name: drop_location
        }
        inputdata.push(drop)
        let data = {
            customer_id: "",
            pickup_lat: from_lat,
            pickup_lon: from_lon,
            drop_lat: to_lat,
            drop_lon: to_lon,
            vehicle_id: vehicle_typeArr,
            service_type: "airport",
            ride_date: ride_date,
            flight_eta: arrival_time,
            flight_number: flight_no,
            flight_date: flight_date,
            flight_carrier_code: flight_carrier_code,
            luggage: Luggage,
            flight_code: flight_code,
            flight_actual_arrival_time: flight_actual_arrival_time,
            ride_time: arrival_time,
            wheel_chair: Wheelchair,
            promocode: "",
            no_of_adults: Passenger,
            no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
            extra_stops: JSON.stringify(inputdata),
            date: moment(AirDate).format('YYYY-MM-DD'),
            pick_up_time: arrival_time,
            guest_type: 1,
            addon_services: cartItems.length > 0 ? JSON.stringify(cartItems) : '',
            insurance: airInsurance == true ? 1 : 0,
        }
        var FareData = {};
        await ApiCall('guest_fare_estimate', data).then((data) => {
            FareData = data;
        }).catch(err => console.log(err));
        return FareData
    }

    // geo fence return
    const [AirDateRe, setAirDateRe] = useState("")
    const [arrival_timeRe, setArrivalTimeRe] = useState("")
    const [flight_noRe, setFlightNoRe] = useState("")
    const [PassengerRe, setPassengerRe] = useState("1")
    const [WheelchairRe, setWheelchairRe] = useState("0")
    const [LuggageRe, setLuggagesRe] = useState("0")
    const [vehicle_typeArrRe, setVehicleTypeArrRe] = useState("")
    const [vehicle_nameRe, setVehicleNameRe] = useState("")
    const [flight_actual_arrival_timeRe, setflight_actual_arrival_timeRe] = useState("")
    const [flight_carrier_codeRe, setflight_carrier_codeRe] = useState("")
    const [flight_codeRe, setflight_codeRe] = useState("")
    const [ride_dateRe, setride_dateRe] = useState("")
    const [flight_dateRe, setflight_dateRe] = useState("")
    const [pick_up_timeRe, setPickUpTimeRe] = useState("")
    const [airport_checkRe, setairport_checkRe] = useState(0)
    const [changeflightDivRe, setchangeflightDivRe] = useState(0)
    const [flightDivRe, setflightDivRe] = useState(0)
    const [flight_dataRe, setFlightDataRe] = useState([])
    const [vehDivRe, setvehDivRe] = useState(0)
    const [VehicleoptionsRe, setVehicleoptionsRe] = useState([
        { value: '', name: 'vehicle_typeAir', text: 'car', label: <div>Vehicle Type </div> }
    ])

    const selectChangeAirrRe = (event) => {
        if (event.name == 'vehicle_typeAir') {
            setVehicleTypeArrRe(event.value)
            setVehicleNameRe(event.text)
            // addon check
            setCartItems([])
            setGeoAddonShow(0)
            OverallVehicleGeo.filter(function (option) {
                if (option.id == event.value && option.addon_services.length > 0) {
                    setAddonList(option.addon_services)
                    setMaxAddon(option.maximum_count)
                    setGeoAddonShow(1)
                }
            })
        }
    }

    const onDateChangeAirRe = (event) => {
        setAirDateRe(event)
    }

    const inputChangeAirRe = (event) => {
        let { name, value } = event.target;
        if (name == 'arrival_time') {
            setArrivalTimeRe(value)
        } else if (name == 'flight_number') {
            setFlightNoRe(value)
        }
    }

    const checkFlightRe = async () => {
        if (AirDateRe == "") {
            toast.error('Select date', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return false
        }

        if (flight_noRe.trim() == "") {
            toast.error('Enter flight number', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return false
        }

        let data = {
            date: moment(AirDateRe).format('YYYY-MM-DD'),
            flight_number: flight_noRe,
        }
        await ApiCall('getScheduledFlightDetails', data).then((data) => {
            if (data.message === "Flight details listed successfully") {
                setFlightDataRe(data.result[0])

                var dataFgt = data.result[0]
                setflight_actual_arrival_timeRe(dataFgt.FlightArrivalTime)
                setflight_carrier_codeRe(dataFgt.carrierFsCode)
                setflight_codeRe(dataFgt.flightNumber)
                setride_dateRe(moment(dataFgt.FlightArrivalTime).format('YYYY-MM-DD'))
                setflight_dateRe(moment(dataFgt.FlightArrivalTime).format('YYYY-MM-DD'))
                setairport_checkRe(1)
                setflightDivRe(1)
                setchangeflightDivRe(1)
                // pick_up_time calculation
                var flightArrivalTime = new Date(dataFgt.FlightArrivalTime)
                var arrivalTime = parseInt(arrival_timeRe, 10)
                var calTime = arrivalTime * 60000
                var pick_upTime = new Date(flightArrivalTime.getTime() + calTime)
                setPickUpTimeRe(pick_upTime.toTimeString().slice(0, 8))
                ChooseVehRe()
            } else {
                toast.error(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return false
            }
        }).catch(err => console.log(err));
    }

    // passenger
    const passengerInRe = (event) => {
        let value = PassengerRe;
        value++;
        setPassengerRe(value)
    }

    const passengerOutRe = (event) => {
        let value = PassengerRe;
        if (value > 1) {
            value--
        }
        setPassengerRe(value)
    }

    // wheel chair
    const wheelchairInRe = (event) => {
        let value = WheelchairRe;
        value++;
        setWheelchairRe(value)
    }

    const wheelchairOutRe = (event) => {
        let value = WheelchairRe;
        if (value > 0) {
            value--
        }
        setWheelchairRe(value)
    }
    // luggage
    const luggageInRe = (event) => {
        let value = LuggageRe;
        value++;
        setLuggagesRe(value)
    }

    const luggageOutRe = (event) => {
        let value = LuggageRe;
        if (value > 0) {
            value--
        }
        setLuggagesRe(value)
    }

    const ChooseVehRe = async () => {
        let data = {
            service_type: "airport",
            no_of_seats: PassengerRe,
            luggage: LuggageRe,
            wheel_chair: WheelchairRe,
        }
        await ApiCall('getVehicleCategoryGuest', data).then((data) => {
            if (data.success === "success") {
                const vechData = data.data;
                setVehicleoptionsRe([
                    { value: '', name: 'vehicle_typeAir', text: 'car', label: <div>Vehicle Type </div> }
                ])
                setOverallVehicleGeo("");
                if (vechData.length > 0) {
                    const newVechOptions = vechData.map((data, i) => ({
                        value: data.id,
                        name: 'vehicle_typeAir',
                        text: data.vehicle_type,
                        label: (
                            <div>
                                <img src={ApiKey.main_name + '/' + data.image} className="option-vehimage" />
                                {data.vehicle_type.split('_').join(" ")}
                            </div>
                        ),
                    }));
                    setVehicleoptionsRe((prevSubOptions) => [...prevSubOptions, ...newVechOptions])
                    setvehDivRe(1)
                    setVehicleBtnRe(1)
                    setOverallVehicleGeo(vechData)
                }
            } else {
                toast.error('Vehicle category not found.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return false
            }
        }).catch(err => console.log(err));
    }

    const fareAPICallRe = async () => {
        const inputdata = []
        var pick_up = {
            lat: from_lat,
            long: from_lon,
            stop_name: pickup_location,
        };
        inputdata.push(pick_up)
        StopDivs.map((data, i) => {
            inputdata.push(data)
        })
        var drop = {
            lat: to_lat,
            lon: to_lon,
            stop_name: drop_location
        }
        inputdata.push(drop)
        let data = {
            customer_id: "",
            pickup_lat: from_lat,
            pickup_lon: from_lon,
            drop_lat: to_lat,
            drop_lon: to_lon,
            vehicle_id: vehicle_typeArrRe,
            service_type: "airport",
            ride_date: ride_dateRe,
            flight_eta: arrival_timeRe,
            flight_number: flight_noRe,
            flight_date: flight_dateRe,
            flight_carrier_code: flight_carrier_codeRe,
            luggage: LuggageRe,
            flight_code: flight_codeRe,
            flight_actual_arrival_time: flight_actual_arrival_timeRe,
            ride_time: arrival_timeRe,
            wheel_chair: WheelchairRe,
            promocode: "",
            no_of_adults: PassengerRe,
            no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
            extra_stops: JSON.stringify(inputdata),
            date: moment(AirDateRe).format('YYYY-MM-DD'),
            pick_up_time: arrival_timeRe,
            guest_type: 1,
            addon_services: RecartItems.length > 0 ? JSON.stringify(RecartItems) : '',
            insurance: returnairInsurance == true ? 1 : 0,
        }
        var FareData = {};
        await ApiCall('guest_fare_estimate', data).then((data) => {
            FareData = data;
        }).catch(err => console.log(err));
        return FareData
    }


    // normal booking
    const onDateChange = (event) => {
        setSchDate(event)
    }

    const onTimeChange = (event) => {
        const timeValue = new Date(event);
        const formattedTime = moment(timeValue).format('hh:mm a');
        setSchTime(formattedTime)
    }

    const onReDateChange = (event) => {
        setReSchDate(event)
    }

    const onReTimeChange = (event) => {
        const timeValue = new Date(event);
        const formattedTime = moment(timeValue).format('hh:mm a');
        setReSchTime(formattedTime)
    }

    let propsTime = {
        placeholder: 'Time'
    }

    let timeProps = {
        placeholder: 'Time',
        disabled: true,
    };

    let timePropsE = {
        placeholder: 'Time',
        disabled: false
    }

    const ReturnSwitch = () => {
        if (ReturnDiv == 0) {
            setReturnDiv(1)
        } else {
            setReturnDiv(0)
        }
    }

    // Single Add Stop
    const RemoveStop = (i) => {
        const deletVal = [...StopDivs]
        deletVal.splice(i, 1)
        setStopDivs(deletVal)
        localStorage.removeItem('stopPosition' + i)
        window.dispatchEvent(new Event("storage"));
    }

    const inputRefs = useRef([]);
    const fromhandleChange = (ref, index) => {
        inputRefs.current[index] = ref;
    }

    const handleChange = async (place, i) => {
        const inputdata = [...StopDivs]
        if (place) {
            var stopLat = place.geometry.location.lat()
            var stopLon = place.geometry.location.lng()
            inputdata[i] = {
                lat: stopLat,
                long: stopLon,
                stop_name: place.formatted_address,
            };
        }
        setStopDivs(inputdata)
        // local storage
        const StopPosition = {
            lat: stopLat,
            lng: stopLon
        }
        localStorage.setItem('stopPosition' + i, JSON.stringify(StopPosition))
        window.dispatchEvent(new Event("storage"));
    }

    const AddStop = () => {
        const data = [...StopDivs, []]
        setStopDivs(data)
    }

    //Return Add Stop
    const ReRemoveStop = (i) => {
        const deletVal = [...ReStopDivs]
        deletVal.splice(i, 1)
        setReStopDivs(deletVal)
        // local storage
        localStorage.removeItem('stopPosition' + i)
        window.dispatchEvent(new Event("storage"));
    }

    const RinputRefs = useRef([]);
    const fromRhandleChange = (ref, index) => {
        inputRefs.current[index] = ref;
    }

    const RehandleChange = async (place, i) => {
        const inputdata = [...ReStopDivs]
        if (place) {
            var stopLat = place.geometry.location.lat()
            var stopLon = place.geometry.location.lng()
            inputdata[i] = {
                lat: stopLat,
                long: stopLon,
                stop_name: place.formatted_address,
            };
        }
        setReStopDivs(inputdata)
        // local storage
        const StopPosition = {
            lat: stopLat,
            lng: stopLon
        }
        localStorage.setItem('stopPosition' + i, JSON.stringify(StopPosition))
        window.dispatchEvent(new Event("storage"));
    }

    const ReAddStop = () => {
        const data = [...ReStopDivs, []]
        setReStopDivs(data)
    }

    // google auto complete

    // pickup
    const fromLoad = (autocomplete) => {
        setFromPlaces(autocomplete)
    }

    const onFromPlaceChanged = async () => {
        if (from_places !== null) {
            var pickLat = from_places.getPlace().geometry.location.lat()
            var pickLon = from_places.getPlace().geometry.location.lng()
            var pickAdd = from_places.getPlace().formatted_address
            var pickName = from_places.getPlace().name

            setFromLat(pickLat)
            setFromLon(pickLon)
            setPickupLocation(pickAdd)
            setFromPlaceName(pickName);
            // return data
            setRToLat(pickLat)
            setRToLon(pickLon)
            setRDropLocation(pickAdd)

            // store localstorage
            localStorage.setItem('pick_up', pickName);
            const PickPosition = {
                lat: pickLat,
                lng: pickLon
            }
            localStorage.setItem('pickupPosition', JSON.stringify(PickPosition))
            window.dispatchEvent(new Event("storage"));
        }
    }

    // drop
    const toLoad = (autocomplete) => {
        setToPlaces(autocomplete)
    }

    const onToPlaceChanged = async () => {
        if (to_places !== null) {
            var dropLat = to_places.getPlace().geometry.location.lat()
            var dropLon = to_places.getPlace().geometry.location.lng()
            var dropAdd = to_places.getPlace().formatted_address
            var dropName = to_places.getPlace().name
            setToLat(dropLat)
            setToLon(dropLon)
            setDropLocation(dropAdd)
            setDropPlaceName(dropName);
            // return data
            setRFromLat(dropLat)
            setRFromLon(dropLon)
            setRPickupLocation(dropAdd)

            // localstorage
            localStorage.setItem('drop', dropName);
            const DropPosition = {
                lat: dropLat,
                lng: dropLon
            }
            localStorage.setItem('dropoffPosition', JSON.stringify(DropPosition))
            window.dispatchEvent(new Event("storage"));
        }
    }

    // return pick
    const rfromLoad = (autocomplete) => {
        setRFromPlaces(autocomplete)
    }

    const onrFromPlaceChanged = async () => {
        if (rfrom_places !== null) {
            var pickRLat = rfrom_places.getPlace().geometry.location.lat()
            var pickRLon = rfrom_places.getPlace().geometry.location.lng()
            var pickRAdd = rfrom_places.getPlace().formatted_address
            var pickRName = rfrom_places.getPlace().name

            setRFromLat(pickRLat)
            setRFromLon(pickRLon)
            setRPickupLocation(pickRAdd)
            // localstroage
            localStorage.setItem('pick_up', pickRName);
            const PickPosition = {
                lat: pickRLat,
                lng: pickRLon
            }
            localStorage.setItem('pickupPosition', JSON.stringify(PickPosition))
            window.dispatchEvent(new Event("storage"));
        }
    }

    // return drop
    const rtoLoad = (autocomplete) => {
        setRToPlaces(autocomplete)
    }

    const onrToPlaceChanged = async () => {
        if (rto_places !== null) {
            var dropRLat = rto_places.getPlace().geometry.location.lat()
            var dropRLon = rto_places.getPlace().geometry.location.lng()
            var dropRAdd = rto_places.getPlace().formatted_address
            var dropRName = rto_places.getPlace().name

            setRToLat(dropRLat)
            setRToLon(dropRLon)
            setRDropLocation(dropRAdd)
            // local storage
            localStorage.setItem('drop', dropRName);
            const DropPosition = {
                lat: dropRLat,
                lng: dropRLon
            }
            localStorage.setItem('dropoffPosition', JSON.stringify(DropPosition))
            window.dispatchEvent(new Event("storage"));
        }
    }

    const inputChange = (event) => {
        let { name, value } = event.target;
        if (name == 'email') {
            setEmail(value)
        } else if (name == 'main_name') {
            setMainName(value)
        } else if (name == 'cc') {
            setCc(value)
        } else if (name == 'phone') {
            setPhone(value)
        } else if (name == 'rcomment') {
            setRComment(value)
        } else {
            setComment(value)
        }
    }

    const FormValidation = () => {
        let isValid = true;
        setFormError({})
        if (pickup_location.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'pickup': 'Enter Pickup Location' }))
        }
        if (drop_location.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'drop': 'Enter Drop Location' }))
        }
        // if (main_category == "") {
        //     isValid = false;
        //     setFormError(prevError => ({ ...prevError, 'mainc': 'Select Main Category' }))
        // }
        // if (sub_category == "") {
        //     isValid = false;
        //     setFormError(prevError => ({ ...prevError, 'sub': 'Select Sub Category' }))
        // }
        if (vehicle_type == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'vehicle': 'Select Vehicle Type' }))
        }
        if (SchDate == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'date': 'Select Date' }))
        }
        if (SchTime == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'time': 'Select Time' }))
        }
        // return validation
        if (ReturnDiv == 1 && rpickup_location.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rpickup': 'Enter Pickup Location' }))
        }
        if (ReturnDiv == 1 && rdrop_location == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rdrop': 'Enter Drop Location' }))
        }
        if (ReturnDiv == 1 && ReSchDate == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rdate': 'Select Date' }))
        }
        if (ReturnDiv == 1 && ReSchTime == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rtime': 'Select Time' }))
        }
        // geo fence
        if (geo_fence == 1 && AirDate == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'dateAir': 'Select date' }))
        }
        if (geo_fence == 1 && arrival_time == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'arrivalAir': 'Select Arrival Time' }))
        }
        if (geo_fence == 1 && flight_no == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'flightAir': 'Enter Flight No' }))
        }
        if (geo_fence == 1 && vehicle_typeArr == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'vehicleAir': 'Select Vehicle' }))
        }
        // geo fence return
        if (geo_fence_return == 1 && AirDateRe == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'dateAirRe': 'Select date' }))
        }
        if (geo_fence_return == 1 && arrival_timeRe == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'arrivalAirRe': 'Select Arrival Time' }))
        }
        if (geo_fence_return == 1 && flight_noRe == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'flightAirRe': 'Enter Flight No' }))
        }
        if (geo_fence_return == 1 && vehicle_typeArrRe == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'vehicleAirRe': 'Select Vehicle' }))
        }

        // stop
        if (StopDivs.length != 0) {
            StopDivs.map((data, i) => {
                if (i == 0 && data.length == 0) {
                    isValid = false;
                    setFormError(prevError => ({ ...prevError, 'stop1': 'Enter Stop 1' }))
                }
                if (i == 1 && data.length == 0) {
                    isValid = false;
                    setFormError(prevError => ({ ...prevError, 'stop2': 'Enter Stop 2' }))
                }
            })
        }
        // restop
        if (ReStopDivs.length != 0) {
            ReStopDivs.map((data, i) => {
                if (i == 0 && data.length == 0) {
                    isValid = false;
                    setFormError(prevError => ({ ...prevError, 'rstop1': 'Enter Stop 1' }))
                }
                if (i == 1 && data.length == 0) {
                    isValid = false;
                    setFormError(prevError => ({ ...prevError, 'rstop2': 'Enter Stop 2' }))
                }
            })
        }

        return isValid;
    }

    function rideTime(date, time) {
        var changeDate = moment(date).format('DD/MM/YYYY') + ' ' + time;
        var flightArrivalTime = new Date(changeDate)
        var calTime = 5 * 60000;
        var pick_upTime = new Date(flightArrivalTime.getTime() - calTime)

        return moment(pick_upTime).format('YYYY-MM-DD HH:mm')
    }

    //booking
    async function ScheduleNow(event) {
        event.preventDefault();
        setCartButton(1)
        if (FormValidation()) {

            if (geo_fence == 1 && changeflightDiv != 1) {
                toast.error('Please Check Flight Details', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setCartButton(0)
                return false
            }

            if (geo_fence_return == 1 && changeflightDivRe != 1) {
                toast.error('Please Check Flight Details', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setCartButton(0)
                return false
            }

            if (ReturnDiv == 1) {
                var from_date = moment(SchDate).format('YYYY-MM-DD') + ' ' + moment(SchTime, 'hh:mm A').format('HH:mm:ss')
                var to_date = moment(ReSchDate).format('YYYY-MM-DD') + ' ' + moment(ReSchTime, 'hh:mm A').format('HH:mm:ss')
                if (new Date(from_date).getTime() >= new Date(to_date).getTime()) {
                    toast.error('Return time must be greater than 15 mins.', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false
                }
            }


            if (geo_fence == 0) {
                const Values = await SchedulefData()
                if (Values.message == "Pickup inside geofencing") {
                    toast.success('Your pickup location got inside the geofence', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    setGeoFence(1)
                    setAirDate(SchDate)
                    $('RGeoclose').click()
                    $('#GeoFencebtn').click()
                    return false;
                }
                else if (Values.message != 'success') {
                    toast.error(Values.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false;
                } else if (Values.result.total_distance == 0) {
                    toast.error('Something went wrong. Please try again in a min. Error Code: D0001', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false;
                }

            }

            // airport
            if (geo_fence == 1) {
                const Airr = await fareAPICall()
                if (Airr.message != 'success') {
                    toast.error(Airr.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false;
                } else if (Airr.result.total_distance == 0) {
                    toast.error('Something went wrong. Please try again in a min. Error Code: D0001', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false;
                }
            }

            // return
            if (geo_fence != 1 && geo_fence_return == 0 && ReturnDiv == 1) {
                const RValues = await SchedulefrData()
                if (RValues.message == "Return pickup inside geofencing") {
                    toast.success('Your return pickup location got inside the geofence', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    setGeoFenceReturn(1)
                    setAirDateRe(ReSchDate)
                    $('#Geoclose').click();
                    $('#GeoFenceReturnbtn').click()
                    return false;
                } else if (RValues.message != 'success') {
                    toast.error(RValues.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false;
                } else if (RValues.result.return_trip.total_distance == 0) {
                    toast.error('Something went wrong. Please try again in a min. Error Code: D0001', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false;
                }
            }

            if (geo_fence_return == 1) {
                const Airr = await fareAPICallRe()
                if (Airr.message != 'success') {
                    toast.error(Airr.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false;
                } else if (Airr.result.total_distance == 0) {
                    toast.error('Something went wrong. Please try again in a min. Error Code: D0001', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false;
                }
            }

            // from
            const inputdata = []
            var pick_up = {
                lat: from_lat,
                long: from_lon,
                stop_name: pickup_location,
            };
            inputdata.push(pick_up)
            StopDivs.map((data, i) => {
                inputdata.push(data)
            })
            var drop = {
                lat: to_lat,
                lon: to_lon,
                stop_name: drop_location
            }
            inputdata.push(drop)
            var extra_stop = JSON.stringify(inputdata)
            if (geo_fence == 1) {
                // let ride_time = rideTime(moment(AirDate).format('YYYY-MM-DD'), pick_up_time)
                const Airr = await fareAPICall()
                var surge = JSON.stringify(Airr.result.surgeprice)
                store.dispatch({
                    type: "addToCart",
                    payload: {
                        pick_up: pickup_location,
                        pickup_lat: from_lat,
                        pickup_lon: from_lon,
                        date: moment(AirDate).format('YYYY-MM-DD'),
                        today_date: moment(AirDate).format('YYYY-MM-DD'),
                        // ride_time: ride_time,
                        flight_eta: arrival_time,
                        flight_number: flight_no.toUpperCase(),
                        customer_id: "",
                        mode: 'ridelater',
                        rider_type: 3,
                        user_type: 3,
                        trip_type: 3,
                        request_type: "web",
                        service_type: "airport",
                        ride_date: ride_date,
                        flight_date: flight_date,
                        flight_actual_arrival_time: flight_actual_arrival_time,
                        flight_carrier_code: flight_carrier_code,
                        flight_code: flight_code,
                        no_of_adults: Passenger,
                        luggage: Luggage,
                        wheel_chair: Wheelchair,
                        pick_up_time: pick_up_time,
                        airport_type: "",
                        no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
                        extra_stops: encodeURIComponent(extra_stop),
                        return_no_of_stops: 0,
                        return_extra_stops: "",
                        return_ride_time: "",
                        return_type: "",
                        return_vehicle_type: "",
                        return_vehicle_id: "",
                        time_validate: "",
                        drop_location: drop_location,
                        drop_lat: to_lat,
                        drop_lon: to_lon,
                        vehicle_id: vehicle_typeArr,
                        short_name: "",
                        // passenger_name: main_name,
                        // passenger_phone_code: cc,
                        // passenger_phone_number: cc + '' + phone,
                        // passenger_email: email,
                        promocode: "",
                        special_note: comment,
                        return_pickup_lat: "",
                        return_pickup_lon: "",
                        return_drop_lat: "",
                        return_drop_lon: "",
                        type: "Airport",
                        vehicle_typeName: vehicle_name,
                        total_amount: parseFloat(Airr.result.amount).toFixed(2),
                        total_distance: Airr.result.total_distance,
                        total_minute: Airr.result.total_minute,
                        extra_stops: Airr.result.extra_stops,
                        extra_stop_status: Airr.result.extra_stop_status,
                        per_stop_fare: Airr.result.per_stop_price,
                        per_stop_increase_cap_fare: Airr.result.per_stop_increase_price,
                        per_stop_multiple: Airr.result.per_stop_multiple,
                        per_stop_increase_multiple: Airr.result.per_stop_increase_multiple,
                        overall_extra_stop_cost: Airr.result.overall_extra_stop_cost,
                        minimum_distance_fare: Airr.result.minimum_fare,
                        minimum_distance_fare_status: Airr.result.minimum_fare_status,
                        sub_total: Airr.result.sub_total,
                        cap_price: Airr.result.cap_price,
                        cap_status: Airr.result.capped_status,
                        base_fare: Airr.result.base_fare,
                        surge_price: Airr.result.surgeprice.length > 0 ? surge : '[]',
                        vehicle_type: vehicle_name,
                        addon_amount: Airr.result.addon_amount,
                        addon_data: Airr.result.addon_data.length > 0 ? Airr.result.addon_data : '',
                        insurance: airInsurance,
                        gst : Airr.result.gst
                    }
                });
            } else {
                // let ride_time = rideTime(moment(SchDate).format('YYYY-MM-DD'), SchTime)
                const Values = await SchedulefData()
                var surgeR = JSON.stringify(Values.result.surgeprice)
                store.dispatch({
                    type: "addToCart",
                    payload: {
                        pick_up: pickup_location,
                        pickup_lat: from_lat,
                        pickup_lon: from_lon,
                        drop_location: drop_location,
                        drop_lat: to_lat,
                        drop_lon: to_lon,
                        customer_id: 0,
                        mode: "ridelater",
                        no_of_adults: MaxPassenger,
                        luggage: MaxLuggage,
                        rider_type: 3,
                        user_type: 3,
                        trip_type: 3,
                        request_type: "web",
                        return_type: "",
                        return_vehicle_type: "",
                        return_ride_time: "",
                        return_vehicle_id: "",
                        service_type: 'normal',
                        time_validate: "",
                        ride_date: moment(SchDate).format('YYYY-MM-DD'),
                        no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
                        extra_stops: encodeURIComponent(extra_stop),
                        return_no_of_stops: 0,
                        return_extra_stops: "",
                        main_vehicle_id: main_category,
                        sub_vehicle_id: sub_category,
                        vehicle_id: vehicle_type,
                        date: moment(SchDate).format('YYYY-MM-DD'),
                        pick_up_time: SchTime,
                        // ride_time: ride_time,
                        short_name: "",
                        // passenger_name: main_name,
                        // passenger_phone_code: cc,
                        // passenger_phone_number: cc + '' + phone,
                        // passenger_email: email,
                        promocode: "",
                        special_note: comment,
                        return_pickup_lat: "",
                        return_pickup_lon: "",
                        return_drop_lat: "",
                        return_drop_lon: "",
                        type: "Schedule",
                        vehicle_typeName: rvehicle_type,
                        total_amount: parseFloat(Values.result.amount).toFixed(2),
                        total_distance: Values.result.total_distance,
                        total_minute: Values.result.total_minute,
                        extra_stops: Values.result.extra_stops,
                        extra_stop_status: Values.result.extra_stop_status,
                        per_stop_fare: Values.result.per_stop_price,
                        per_stop_increase_cap_fare: Values.result.per_stop_increase_price,
                        per_stop_multiple: Values.result.per_stop_multiple,
                        per_stop_increase_multiple: Values.result.per_stop_increase_multiple,
                        overall_extra_stop_cost: Values.result.overall_extra_stop_cost,
                        vehicle_type: rvehicle_type,
                        today_date: moment(SchDate).format('YYYY-MM-DD'),
                        minimum_distance_fare: Values.result.minimum_fare,
                        minimum_distance_fare_status: Values.result.minimum_fare_status,
                        sub_total: Values.result.sub_total,
                        cap_price: Values.result.cap_price,
                        cap_status: Values.result.capped_status,
                        base_fare: Values.result.base_fare,
                        surge_price: Values.result.surgeprice.length > 0 ? surgeR : '[]',
                        addon_amount: Values.result.addon_amount,
                        addon_data: Values.result.addon_data.length > 0 ? Values.result.addon_data : '',
                        insurance: insurance,
                        gst : Values.result.gst
                    }
                });
            }


            if (ReturnDiv == 1) {

                const inputRdata = []
                var pick_up = {
                    lat: rfrom_lat,
                    long: rfrom_lon,
                    stop_name: rpickup_location,
                };
                inputRdata.push(pick_up)
                ReStopDivs.map((data, i) => {
                    inputRdata.push(data)
                })
                var drop = {
                    lat: rto_lat,
                    lon: rto_lon,
                    stop_name: rdrop_location
                }
                inputRdata.push(drop)
                var extra_stop = JSON.stringify(inputRdata)
                if (geo_fence_return == 1) {
                    // console.log('geo')
                    // let ride_time = rideTime(moment(AirDateRe).format('YYYY-MM-DD'), pick_up_timeRe)
                    const Airr = await fareAPICallRe()
                    var surge = JSON.stringify(Airr.result.surgeprice)
                    store.dispatch({
                        type: "addToCart",
                        payload: {
                            pick_up: rpickup_location,
                            pickup_lat: rfrom_lat,
                            pickup_lon: rfrom_lon,
                            drop_location: rdrop_location,
                            drop_lat: rto_lat,
                            drop_lon: rto_lon,
                            date: moment(AirDateRe).format('YYYY-MM-DD'),
                            today_date: moment(AirDateRe).format('YYYY-MM-DD'),
                            // ride_time: ride_time,
                            flight_eta: arrival_timeRe,
                            flight_number: flight_noRe.toUpperCase(),
                            customer_id: "",
                            mode: 'ridelater',
                            rider_type: 3,
                            user_type: 3,
                            trip_type: 3,
                            request_type: "web",
                            service_type: "airport",
                            ride_date: ride_dateRe,
                            flight_date: flight_dateRe,
                            flight_actual_arrival_time: flight_actual_arrival_timeRe,
                            flight_carrier_code: flight_carrier_codeRe,
                            flight_code: flight_codeRe,
                            no_of_adults: PassengerRe,
                            luggage: LuggageRe,
                            wheel_chair: WheelchairRe,
                            pick_up_time: pick_up_timeRe,
                            airport_type: "",
                            no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
                            extra_stops: encodeURIComponent(extra_stop),
                            return_no_of_stops: 0,
                            return_extra_stops: "",
                            return_ride_time: "",
                            return_type: "",
                            return_vehicle_type: "",
                            return_vehicle_id: "",
                            time_validate: "",
                            vehicle_id: vehicle_typeArrRe,
                            short_name: "",
                            // passenger_name: main_name,
                            // passenger_phone_code: cc,
                            // passenger_phone_number: cc + '' + phone,
                            // passenger_email: email,
                            promocode: "",
                            special_note: comment,
                            return_pickup_lat: "",
                            return_pickup_lon: "",
                            return_drop_lat: "",
                            return_drop_lon: "",
                            type: "Airport",
                            vehicle_typeName: vehicle_nameRe,
                            total_amount: parseFloat(Airr.result.amount).toFixed(2),
                            total_distance: Airr.result.total_distance,
                            total_minute: Airr.result.total_minute,
                            extra_stops: Airr.result.extra_stops,
                            extra_stop_status: Airr.result.extra_stop_status,
                            per_stop_fare: Airr.result.per_stop_price,
                            per_stop_increase_cap_fare: Airr.result.per_stop_increase_price,
                            per_stop_multiple: Airr.result.per_stop_multiple,
                            per_stop_increase_multiple: Airr.result.per_stop_increase_multiple,
                            overall_extra_stop_cost: Airr.result.overall_extra_stop_cost,
                            minimum_distance_fare: Airr.result.minimum_fare,
                            minimum_distance_fare_status: Airr.result.minimum_fare_status,
                            sub_total: Airr.result.sub_total,
                            cap_price: Airr.result.cap_price,
                            cap_status: Airr.result.capped_status,
                            base_fare: Airr.result.base_fare,
                            surge_price: Airr.result.surgeprice.length > 0 ? surge : '[]',
                            vehicle_type: vehicle_nameRe,
                            addon_amount: Airr.result.addon_amount,
                            addon_data: Airr.result.addon_data.length > 0 ? Airr.result.addon_data : '',
                            insurance: returnairInsurance,
                            gst : Airr.result.gst
                        }
                    });
                } else {
                    const RValues = await SchedulefrData()
                    // let ride_time = rideTime(moment(ReSchDate).format('YYYY-MM-DD'), ReSchTime)
                    var surgeR = JSON.stringify(RValues.result.return_trip.surgeprice)
                    store.dispatch({
                        type: "addToCart",
                        payload: {
                            pick_up: rpickup_location,
                            pickup_lat: rfrom_lat,
                            pickup_lon: rfrom_lon,
                            drop_location: rdrop_location,
                            drop_lat: rto_lat,
                            drop_lon: rto_lon,
                            no_of_adults: MaxPassenger,
                            luggage: MaxLuggage,
                            customer_id: 0,
                            mode: "ridelater",
                            rider_type: 3,
                            user_type: 3,
                            trip_type: 3,
                            request_type: "web",
                            return_type: "return",
                            service_type: 'normal',
                            return_vehicle_type: rvehicle_type,
                            return_ride_time: ReSchTime,
                            return_vehicle_id: vehicle_type,
                            time_validate: "1",
                            no_of_stops: inputRdata.length > 2 ? inputRdata.length - 2 : 0,
                            extra_stops: encodeURIComponent(extra_stop),
                            return_no_of_stops: 0,
                            return_extra_stops: "",
                            main_vehicle_id: main_category,
                            sub_vehicle_id: sub_category,
                            vehicle_id: vehicle_type,
                            date: moment(ReSchDate).format('YYYY-MM-DD'),
                            ride_date: moment(ReSchDate).format('YYYY-MM-DD'),
                            pick_up_time: ReSchTime,
                            // ride_time: ride_time,
                            short_name: "",
                            // passenger_name: main_name,
                            // passenger_phone_code: cc,
                            // passenger_phone_number: cc + '' + phone,
                            // passenger_email: email,
                            promocode: "",
                            special_note: rcomment,
                            return_pickup_lat: rfrom_lat,
                            return_pickup_lon: rfrom_lon,
                            return_drop_lat: rto_lat,
                            return_drop_lon: rto_lon,
                            type: "Schedule",
                            vehicle_typeName: rvehicle_type,
                            total_amount: parseFloat(RValues.result.return_trip.amount).toFixed(2),
                            total_distance: RValues.result.return_trip.total_distance,
                            total_minute: RValues.result.return_trip.total_minute,
                            extra_stops: RValues.result.return_trip.extra_stops,
                            extra_stop_status: RValues.result.return_trip.extra_stop_status,
                            per_stop_fare: RValues.result.return_trip.per_stop_price,
                            per_stop_increase_cap_fare: RValues.result.return_trip.per_stop_increase_price,
                            per_stop_multiple: RValues.result.return_trip.per_stop_multiple,
                            per_stop_increase_multiple: RValues.result.return_trip.per_stop_increase_multiple,
                            overall_extra_stop_cost: RValues.result.return_trip.overall_extra_stop_cost,
                            vehicle_type: rvehicle_type,
                            today_date: moment(ReSchDate).format('YYYY-MM-DD'),
                            minimum_distance_fare: RValues.result.return_trip.minimum_fare,
                            minimum_distance_fare_status: RValues.result.return_trip.minimum_fare_status,
                            sub_total: RValues.result.return_trip.sub_total,
                            cap_price: RValues.result.return_trip.cap_price,
                            cap_status: RValues.result.return_trip.capped_status,
                            base_fare: RValues.result.return_trip.base_fare,
                            surge_price: RValues.result.return_trip.surgeprice.length > 0 ? surgeR : '[]',
                            addon_amount: RValues.result.return_trip.addon_amount,
                            addon_data: RValues.result.return_trip.addon_data.length > 0 ? RValues.result.return_trip.addon_data : '',
                            insurance: returnInsurance,
                            gst : RValues.result.gst
                        }
                    });
                }
            }

            toast.success('Booking added into cart', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            setTimeout(() => {
                window.location.reload();
            }, 5000);

        } else {
            setCartButton(0)
        }
    }

    const selectChange = (event) => {
        if (event.name == 'main_category') {
            setMainCategory(event.value)
        } else if (event.name == 'sub_category') {
            setSubCategory(event.value)
        } else if (event.name == 'vehicle_type') {
            setVehicleType(event.value)
            setRVehicleType(event.text)
            // addon old hide
            setAddonData([]);
            setHasRendered(false);
            setAddonStatus(0);
            setAddonShow(0);
            // addon check
            OverallVehicleSch.filter(function (option) {
                if (option.id == event.value && option.addon_services.length > 0) {
                    setAddonShow(1);
                }
            })
        }
    }

    useEffect(() => {
        setSubOption([
            { value: '', name: 'sub_category', label: <div>Sub Category</div> },
        ])
        SubApiCall()
    }, [main_category])

    const SubApiCall = async () => {
        let data = {
            category_id: main_category
        };

        await ApiCall('sub_categories', data).then((data) => {
            if (data.message === "category listed successfully.") {
                const subData = data.result;
                if (subData.length > 0) {
                    const newSubOptions = subData.map((data, i) => ({
                        value: data.id,
                        name: 'sub_category',
                        label: (
                            <div>
                                <img src={ApiKey.main_name + '/' + data.image} className="option-image" />
                                {data.subcategory_name}
                            </div>
                        ),
                    }));

                    setSubOption((prevSubOptions) => [...prevSubOptions, ...newSubOptions]);
                }
            }
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        setVehicleOption([
            { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
        ])
        VehiApiCall()
    }, [sub_category])

    const VehiApiCall = async () => {
        let data = {
            subcategory_id: sub_category
        };
        await ApiCall('vehicleCategory', data).then((data) => {
            if (data.message === "category listed successfully.") {
                const vechData = data.result;
                if (vechData.length > 0) {
                    const newVechOptions = vechData.map((data, i) => ({
                        value: data.id,
                        name: 'vehicle_type',
                        text: data.vehicle_type,
                        label: (
                            <div>
                                <img src={ApiKey.main_name + '/' + data.image} className="option-vehimage" />
                                {data.vehicle_type}
                            </div>
                        ),
                    }));
                    setVehicleOption((prevSubOptions) => [...prevSubOptions, ...newVechOptions])
                }
            }
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        // setvehDiv(0)
        // setVehicleBtn(0)
        setVehicleTypeArr("");
        setVehicleName("")
        setVehicleoptions([
            { value: '', name: 'vehicle_typeAir', text: 'car', label: <div>Vehicle Type </div> }
        ])
        ChooseVeh()
    }, [Passenger, Wheelchair, Luggage])

    useEffect(() => {
        // setflightDivRe(0)
        // setVehicleBtnRe(0)
        setVehicleTypeArrRe("");
        setVehicleNameRe("")
        setVehicleoptionsRe([
            { value: '', name: 'vehicle_typeAir', text: 'car', label: <div>Vehicle Type </div> }
        ])
        ChooseVehRe()
    }, [PassengerRe, WheelchairRe, LuggageRe])

    useEffect(() => {
        setchangeflightDiv(0)
    }, [flight_no, AirDate, arrival_time])

    useEffect(() => {
        setchangeflightDivRe(0)
    }, [flight_noRe, AirDateRe, arrival_timeRe])

    const Selectstyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '25px',
        }),
    };

    async function ScheduleFare() {
        if (pickup_location != '' && drop_location != '' && SchDate != '' && SchTime != '' && vehicle_type != '') {
            const Values = await SchedulefData()
            if (Values.message == "success") {
                if (Values.result.capped_status == 1 && Values.result.extra_stop_status == 1) {
                    setFareTotalAmount(Values.result.amount)
                } else if (Values.result.capped_status == 1 && Values.result.surgeprice.length > 0) {
                    setFareTotalAmount(Values.result.amount)
                } else if (Values.result.capped_status == 1) {
                    setFareTotalAmount(Values.result.cap_price)
                } else {
                    setFareTotalAmount(Values.result.amount)
                }
                setFareData(Values.result)
                setgeoFencetoast(0)
            } else if (Values.message == 'Pickup inside geofencing' || Values.message == 'Return pickup inside geofencing') {
                setgeoFencetoast(1)
            } else {
                setgeoFencetoast(0)
                toast.error(Values.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }

    async function ScheduleFareReturn() {
        if (rpickup_location != '' && rdrop_location != '' && ReSchDate != '' && ReSchTime != '' && vehicle_type != '') {
            const Values = await SchedulefrData()
            if (Values.message == "success") {
                if (Values.result.return_trip.capped_status == 1 && Values.result.return_trip.extra_stop_status == 1) {
                    setFareReTotalAmount(Values.result.return_trip.amount)
                } else if (Values.result.return_trip.capped_status == 1 && Values.result.return_trip.surgeprice.length > 0) {
                    setFareReTotalAmount(Values.result.return_trip.amount)
                } else if (Values.result.return_trip.capped_status == 1) {
                    setFareReTotalAmount(Values.result.return_trip.cap_price)
                } else {
                    setFareReTotalAmount(Values.result.return_trip.amount)
                }
                setReFareData(Values.result.return_trip)
                setgeoFencetoastRe(0)
            } else if (Values.message == 'Return pickup inside geofencing' || Values.message == 'Pickup inside geofencing') {
                setgeoFencetoastRe(1)
            } else {
                setgeoFencetoastRe(0)
                toast.error(Values.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }

    const SchedulefData = async () => {
        const inputdata = []
        var pick_up = {
            lat: from_lat,
            long: from_lon,
            stop_name: pickup_location,
        };
        inputdata.push(pick_up)
        StopDivs.map((data, i) => {
            inputdata.push(data)
        })
        var drop = {
            lat: to_lat,
            lon: to_lon,
            stop_name: drop_location
        }
        inputdata.push(drop)
        var extra_stop = JSON.stringify(inputdata)

        let data = {
            pickup_lat: from_lat,
            pickup_lon: from_lon,
            drop_lat: to_lat,
            drop_lon: to_lon,
            vehicle_id: vehicle_type,
            service_type: "normal",
            ride_date: moment(SchDate).format('YYYY-MM-DD'),
            ride_time: SchTime,
            date: moment(SchDate).format('YYYY-MM-DD'),
            pick_up_time: SchTime,
            promocode: "",
            no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
            extra_stops: encodeURIComponent(extra_stop),
            guest_type: 1,
            addon_services: AddonData.length > 0 ? JSON.stringify(AddonData) : '',
            insurance: insurance == true ? 1 : 0,
        }
        var FareData = {};
        await ApiCall('guest_fare_estimate', data).then((data) => {
            FareData = data;
            console.log("FareData", FareData);
        }).catch(err => console.log(err));

        return FareData
    }

    const SchedulefrData = async () => {

        const inputRdata = []
        var pick_up = {
            lat: rfrom_lat,
            long: rfrom_lon,
            stop_name: rpickup_location,
        };
        inputRdata.push(pick_up)
        ReStopDivs.map((data, i) => {
            inputRdata.push(data)
        })
        var drop = {
            lat: rto_lat,
            lon: rto_lon,
            stop_name: rdrop_location
        }
        inputRdata.push(drop)
        var extra_stop = JSON.stringify(inputRdata)
        let data = {
            customer_id: "",
            pickup_lat: "",
            pickup_lon: "",
            drop_lat: "",
            drop_lon: "",
            vehicle_id: vehicle_type,
            service_type: "normal",
            ride_date: moment(SchDate).format('YYYY-MM-DD'),
            ride_time: SchTime,
            promocode: "",
            return_type: "return",
            return_geo_type: geo_fence != 1 ? "return" : '',
            return_trip: "return",
            return_vehicle_id: vehicle_type,
            return_pickup_lat: rfrom_lat,
            return_pickup_lon: rfrom_lon,
            return_drop_lat: rto_lat,
            return_drop_lon: rto_lon,
            time_validate: "1",
            return_ride_date: moment(ReSchDate).format('YYYY-MM-DD'),
            return_ride_time: ReSchTime,
            return_promocode: "",
            return_no_of_stops: inputRdata.length > 2 ? inputRdata.length - 2 : 0,
            return_extra_stops: encodeURIComponent(extra_stop),
            date: moment(ReSchDate).format('YYYY-MM-DD'),
            pick_up_time: ReSchTime,
            guest_type: 1,
            return_addon_services: ReAddonData.length > 0 ? JSON.stringify(ReAddonData) : '',
            return_insurance: returnInsurance == true ? 1 : 0,
        }
        var FareData = {};
        await ApiCall('guest_fare_estimate', data).then((data) => {
            FareData = data;
        }).catch(err => console.log(err));

        return FareData
    }

    async function MainApi() {
        let data = {};
        await ApiCall('getMainCategoryGuest', data).then((data) => {
            if (data.success === "success") {
                const mainData = data.data;
                if (mainData.length > 0) {
                    const newMainData = mainData.map((data, i) => ({
                        value: data.id,
                        name: 'main_category',
                        label: (
                            <div>
                                <img src={ApiKey.main_name + '/' + data.image} className="option-image" />
                                {data.category_name}
                            </div>
                        ),
                    }));

                    setMainOption((prevSubOptions) => [...prevSubOptions, ...newMainData]);
                }
            } else {
                toast.error('Main category not found.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch(err => console.log(err));
    }

    async function MainTextApi() {
        const searchParams = new URLSearchParams(window.location.search);
        const vehicle_name = searchParams.get('type');
        let data = {
            vehicle_name: vehicle_name
        }
        await ApiCall('getMainCategoryId', data).then((data) => {
            if (data.success === "success") {
                setMainCategory(data.data)
                setMainDisable(1)
                setTimeout(() => {
                    SubApiCall()
                }, 2000);
            } else {
                toast.error('Main category not found.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch(err => console.log(err));
    }

    async function VehicleApi() {
        let data = {
            no_of_seats: MaxPassenger,
            luggage: MaxLuggage
        };
        await ApiCall('getVehicleCategoryGuest', data).then((data) => {
            if (data.success === "success") {
                const vechData = data.data;
                setVehicleOption([
                    { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
                ])
                setOverallVehicleSch("");
                if (vechData.length > 0) {
                    const newVechOptions = vechData.map((data, i) => ({
                        value: data.id,
                        name: 'vehicle_type',
                        text: data.vehicle_type,
                        label: (
                            <div>
                                <img src={ApiKey.main_name + '/' + data.image} className="option-vehimage" />
                                {data.vehicle_type.split('_').join(" ")}
                            </div>
                        ),
                    }));
                    setVehicleOption((prevSubOptions) => [...prevSubOptions, ...newVechOptions])
                    setvehicleshow(1);
                    setOverallVehicleSch(vechData)
                }
            } else {
                toast.error('Vehicle category not found.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch(err => console.log(err));
    }

    async function VehicleTextApi() {
        const searchParams = new URLSearchParams(window.location.search);
        const vehicle_name = searchParams.get('type');
        let data = {
            vehicle_name: vehicle_name
        }
        await ApiCall('getVehicleId', data).then((data) => {
            if (data.success === "success") {
                setVehicleType(data.data.id)
                setRVehicleType(data.data.vehicle_type)
                setMainDisable(1)
                setTimeout(() => {
                    SubApiCall()
                }, 2000);
            } else {
                toast.error('Main category not found.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch(err => console.log(err));
    }

    async function CountryCodeApi() {
        let data = {}
        await ApiCall('countrycode', data).then((data) => {
            if (data.success === "success") {
                setCountryCode(data.data)
            }
        }).catch(err => console.log(err));
    }

    const AirportFare = async () => {
        if (AirDate != '' && arrival_time != '' && flight_no != '' && Passenger != '' && Wheelchair != '' && Luggage != '' && vehicle_typeArr != '') {
            const Values = await fareAPICall();
            if (Values.message == "success") {
                setFareDataAir(Values.result)
            } else {
                toast.error(Values.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }

    const AirportFareRe = async () => {
        if (AirDateRe != '' && arrival_timeRe != '' && flight_noRe != '' && PassengerRe != '' && WheelchairRe != '' && LuggageRe != '' && vehicle_typeArrRe != '') {
            const Values = await fareAPICallRe();
            if (Values.message == "success") {
                setReFareDataAir(Values.result)
            } else {
                toast.error(Values.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }

    async function VehicleGroupApi() {
        const searchParams = new URLSearchParams(window.location.search);
        const groupName = searchParams.get('type');
        let data = {
            group_name: groupName,
            no_of_seats: MaxPassenger,
            luggage: MaxLuggage
        }
        await ApiCall('vehicleCategoryGroupList', data).then((data) => {
            if (data.message === "success") {
                const vechGrpData = data.result;
                setVehicleOption([
                    { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
                ])
                setOverallVehicleSch("");
                if (vechGrpData.length > 0) {
                    const newVechGrpOptions = vechGrpData.map((data, i) => ({
                        value: data.id,
                        name: 'vehicle_type',
                        text: data.vehicle_type,
                        label: (
                            <div>
                                <img src={ApiKey.main_name + '/' + data.image} className="option-vehimage" />
                                {data.vehicle_type.split('_').join(" ")}
                            </div>
                        ),
                    }));
                    setVehicleOption((prevSubOptions) => [...prevSubOptions, ...newVechGrpOptions])
                    setvehicleshow(1);
                    setOverallVehicleSch(vechGrpData)
                }
            } else {
                // VehicleApi()
                toast.error("No vehicle matches your requirement", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch(err => console.log(err));
    }

    const MaxChooseVeh = () => {
        setVehicleType("");
        setVehicleName("")
        setVehicleOption([
            { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
        ])
        const searchParams = new URLSearchParams(window.location.search);
        const vehicle_name = searchParams.get('type');
        if (vehicle_name == null) {
            VehicleApi()
        } else if (vehicle_name != 'all') {
            VehicleGroupApi()
        } else {
            VehicleApi()
        }
    }

    useEffect(() => {
        // setvehicleshow(0)
        MaxChooseVeh()
    }, [MaxLuggage, MaxPassenger])


    useEffect(() => {
        // const searchParams = new URLSearchParams(window.location.search);
        // const vehicle_name = searchParams.get('type');
        CountryCodeApi()
        // if (vehicle_name == null) {
        //     VehicleApi()
        // } else if (vehicle_name != 'all') {
        //     // MainTextApi()
        //     VehicleTextApi()
        //     setTimeout(() => {
        //         // MainApi()
        //         VehicleApi()
        //     }, 3000);
        // } else {
        //     // MainApi()
        //     VehicleApi()
        // }
        //clear localstorage
        localStorage.removeItem('stopPosition0')
        localStorage.removeItem('stopPosition1')
        localStorage.removeItem('pick_up')
        localStorage.removeItem('pickupPosition')
        localStorage.removeItem('drop')
        localStorage.removeItem('dropoffPosition')
        window.dispatchEvent(new Event("storage"));
    }, [])

    const [insurance, setInsurance] = React.useState(false);
    const [returnInsurance, setReturnInsurance] = React.useState(false);
    const [airInsurance, setAirInsurance] = React.useState(false);
    const [returnairInsurance, setReturnAirInsurance] = React.useState(false);
    const [insCancelHour,setInsCancelHour] = React.useState(0);
    const [insCancelAmount,setInsCancelAmount] = React.useState(0);
    function handleChangeInsurance(e){
        setInsurance(e.target.checked);
    }
    function handleChangeReturnInsurance(e){
        setReturnInsurance(e.target.checked);
    }

    function handleChangeAirInsurance(e){
        setAirInsurance(e.target.checked);
    }

    function handleChangeReAirInsurance(e){
        setReturnAirInsurance(e.target.checked);
    }

    useEffect(() => {
        let insurance_cancellation_hour = localStorage.getItem('cancel_hour');
        let insurance_amount = localStorage.getItem('insurance_amount');
        setInsCancelHour(insurance_cancellation_hour);
        setInsCancelAmount(insurance_amount);
    },[]);

    useEffect(() => {
        setTimeout(() => {
            ScheduleFare()
        }, 1000);
    }, [pickup_location, drop_location, SchDate, SchTime, main_category, sub_category, vehicle_type, StopDivs, AddonData, insurance])


    useEffect(() => {
        setTimeout(() => {
            ScheduleFareReturn()
        }, 1000);
    }, [rpickup_location, rdrop_location, ReSchDate, ReSchTime, main_category, sub_category, vehicle_type, ReStopDivs, ReAddonData,returnInsurance])

    useEffect(() => {
        setTimeout(() => {
            AirportFare()
        }, 1000);
    }, [AirDate, arrival_time, flight_no, Passenger, Wheelchair, Luggage, vehicle_typeArr, cartItems, airInsurance])

    useEffect(() => {
        AirportFareRe()
    }, [AirDateRe, arrival_timeRe, flight_noRe, PassengerRe, WheelchairRe, LuggageRe, vehicle_typeArrRe, RecartItems, returnairInsurance])

    return (
        <>
            <div className='formDiv' id="scheduleBook">
                <form autoComplete='off'>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 mb-2">
                            <h5>Booking Details</h5>
                            {/* <hr /> */}
                            <div className='row mb-1'>
                                {/* <p>Pick Date/Time</p> */}
                                <div className='col-lg-8 col-md-12 mt-1'>
                                    <div className="inner-addon right-addon">
                                        <i className="glyphicon glyphicon-user fa fa-calendar textColor"></i>
                                        {/* <input type="text" className="form-control rounded-pill" placeholder='Date'/> */}
                                        <DatePicker
                                            selected={SchDate}
                                            onChange={onDateChange}
                                            dateFormat='yyyy-MM-dd'
                                            minDate={new Date}
                                            className='form-control'
                                            placeholderText='Date'
                                            name='scheduleDate'
                                        />
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.date}</span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-12 mt-1'>
                                    <div className="inner-addon right-addon">
                                        <i className="glyphicon glyphicon-user fa fa-clock textColor"></i>
                                        {/* <input type="text" className="form-control rounded-pill" placeholder='Time'/> */}
                                        <Datetime dateFormat={false}
                                            inputProps={propsTime}
                                            onChange={onTimeChange}
                                            value={SchTime}
                                            timeConstraints={timeConstraints}
                                            name='scheduleTime'
                                            className='mb-1' />
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.time}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-lg-8 col-12'>
                                    {/* <label className="form-label">Pickup Location</label> */}
                                    <Autocomplete onLoad={fromLoad} onPlaceChanged={onFromPlaceChanged} options={AutocompleteRestrict}>
                                        <input type="text" className="form-control rounded-pill" id="pickup" name="pickup" placeholder='Pickup Location' autoComplete="off" />
                                    </Autocomplete>
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.pickup}</span>
                                </div>
                                <div className='col-4 splitCol'>
                                    <input type="button" className="btn rounded-pill billCart" value='Add Stop' onClick={AddStop} disabled={StopDivs.length == 2 ? true : false} />
                                </div>
                            </div>

                            {StopDivs.map((data, i) => {
                                return (
                                    <div>
                                        <div className="row mb-2">
                                            <div className="col-lg-8 col-10">
                                                <Autocomplete onPlaceChanged={() => handleChange(inputRefs.current[i]?.getPlace(), i)} onLoad={(autocomplete) => fromhandleChange(autocomplete, i)} options={AutocompleteRestrict}>
                                                    <input type="text" className="form-control rounded-pill" placeholder={`Stop ${i + 1}`} defaultValue={data.stop_name} />
                                                </Autocomplete>
                                            </div>
                                            <div className="col-lg-4 col-2 d-flex align-items-center">
                                                <i className="fa fa-times-circle" onClick={() => RemoveStop(i)}></i>
                                            </div>
                                        </div>
                                        <span className="error-field text-dark mb-3" style={{ marginLeft: "0.5rem" }}>{formError['stop' + (i + 1)]}</span>
                                    </div>
                                )
                            })}

                            {/* <div className="text-end mb-2">
                        <input type="button" className="btn rounded-pill billCart" value='Add Stop' onClick={AddStop} disabled={StopDivs.length == 2 ? true : false} />
                    </div> */}

                            <div className='row'>
                                <div className="col-lg-8 col-12">
                                    <Autocomplete onLoad={toLoad} onPlaceChanged={onToPlaceChanged} options={AutocompleteRestrict}>
                                        <input type="text" className="form-control rounded-pill" id="drop" name="drop" placeholder='Drop Location' />
                                    </Autocomplete>
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.drop}</span>
                                </div>
                                <div className="col-lg-2 col-6">
                                    <div className='marginDiv'>
                                        <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>Passenger <i class="fa-solid fa-circle-info" data-tooltip-id="tooltip-passenger"></i></span>
                                        <ReactTooltip id="tooltip-passenger" place="top">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span>Include all ages,</span>
                                                <span>exclude wheelchair user in wheelchair</span>
                                            </div>
                                        </ReactTooltip>
                                        <div className="buttons d-flex align-items-center m-0">
                                            <p className='airport-decrement-btn' id="DecP" onClick={maxpassengerOut}> &nbsp; - &nbsp;</p>
                                            <span className="number_padd" id="passenger-input">{MaxPassenger}</span>
                                            <p className='airport-increment-btn' id="DecP" onClick={maxpassengerIn}> &nbsp; + &nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-6">
                                    <div className='marginDiv'>
                                        <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>Luggage <i class="fa-solid fa-circle-info" data-tooltip-id="tooltip-luggage"></i></span>
                                        <ReactTooltip id="tooltip-luggage" place="top">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span>The standard size is 24 and above.</span>
                                                <span>If greater than 24, please count as 2</span>
                                            </div>
                                        </ReactTooltip>
                                        <div className="buttons d-flex align-items-center m-0">
                                            <p className='airport-decrement-btn' id="DecP" onClick={maxluggageOut}> &nbsp; - &nbsp;</p>
                                            <span className="number_padd" id="wheelchair-input">{MaxLuggage}</span>
                                            <p className='airport-increment-btn' id="DecP" onClick={maxluggageIn}> &nbsp; + &nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='row mb-2'>
                        <p>Choose your Vehicle</p>
                        <div className='col-12'>
                            <div className="mb-1">
                                <Select
                                    styles={Selectstyles}
                                    options={main_option}
                                    placeholder="Main Category"
                                    onChange={selectChange}
                                    isDisabled={main_disable == 1 ? true : false}
                                    value={main_option.filter(function (option) {
                                        return option.value === main_category;
                                    })}
                                />
                                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.mainc}</span>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="mb-1">
                                <Select
                                    styles={Selectstyles}
                                    options={sub_option}
                                    placeholder="Sub Category"
                                    onChange={selectChange}
                                />
                                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.sub}</span>
                            </div>
                        </div>
                    </div> */}


                            <div className="row mt-2">
                                {/* <div className='col-lg-4 col-md-4 col-12 splitCol'>
                                    <input type="button" className="form-control btn rounded-pill billCart" value='Choose Your Vehicle' onClick={MaxChooseVeh} />
                                </div> */}
                                {/* className={vehicleshow == 1 ? "col-lg-8 col-md-8 col-12" : "col-lg-8 col-md-8 col-12 vehopacity"} */}
                                <div className="col-lg-8 col-md-8 col-12">
                                    {/* <label className="form-label">Choose Vehicle Type</label> */}
                                    <Select
                                        styles={Selectstyles}
                                        options={vehicle_option}
                                        placeholder="Vehicle Type"
                                        onChange={selectChange}
                                        // isDisabled={main_disable == 1 ? true : false}
                                        // isDisabled={vehicleshow == 1 ? false : true}
                                        value={vehicle_option.filter(function (option) {
                                            return option.value === vehicle_type;
                                        })}
                                    />
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.vehicle}</span>
                                </div>
                                <div className={AddonShow == 1 ? 'col-lg-4 col-md-4 col-12' : 'd-none'}>
                                    {/* <span className="no_of" style={{ color: 'white' }}>&nbsp;</span> */}
                                    <div class="form-check d-flex justify-content-between">
                                        <div>
                                            <input class="form-check-input" type="checkbox" id="addon" name="addon" onChange={AddonModelOpen} checked={AddonData.length > 0 ? true : false} />
                                            <label class="form-check-label">
                                                Add-on Services
                                            </label>
                                            <i className='fa fa-circle-info ms-1' data-tooltip-id="tooltip-addon"></i>
                                        </div>
                                        <p className={AddonData.length > 0 ? 'text-end addon-edit' : 'text-end addon-edit d-none'} onClick={AddonModelEdit}>Edit</p>
                                        <ReactTooltip id="tooltip-addon" place="top">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {AddonData.length > 0 ? AddonData.map((item, key) => {
                                                    return (
                                                        <span>{item.name} - {item.count}</span>
                                                    )
                                                }) : <><p>No add-on selected</p></>}
                                            </div>
                                        </ReactTooltip>
                                    </div>
                                </div>
                            </div>
                            <div className='p-2'>
                                <input className="form-check-input" type='checkbox' id='insurance' name = 'insurance' onChange={handleChangeInsurance}/>
                                <label className="form-check-label ms-1 me-1">Insurance</label>
                                <i className='fa fa-circle-info ms-1' data-tooltip-id="tooltip-insurance"></i>
                                <ReactTooltip id="tooltip-insurance" place="top">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>Insurance allows free cancellation up to {insCancelHour} hours from the service time.</span>
                                        <span>For a small fee, it gives you a greater flexibility!</span>
                                    </div>
                                </ReactTooltip>
                            </div>
                            <div className="confirnBook mb-1">
                                <p className="text-center confirm-title pt-2">Fare Breakdown</p>
                                <div className="row justify-content-center">
                                    <div className="col-md-11 col-11 pt-1">
                                        <div className='row'>
                                            <div className='col-lg-6 col-12 checkout p-1'>
                                                <div className="container surge_con" id={fareData != '' && (fareData.surgeprice.length > 0 || fareData.addon_data.length > 0) ? 'fare-scroll' : ''}>
                                                    <table id="fare-table">
                                                        {fareData != '' ? <>
                                                            <tr>
                                                                <td style={{ fontSize: '14px' }}>Base Fare</td>
                                                                <td>:</td>
                                                                <td>
                                                                    <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(fareData.base_fare).toFixed(2)}</p>
                                                                </td>
                                                            </tr>
                                                            {fareData.insurance_amount != 0 ? <>
                                                                <tr>
                                                                    <td style={{ fontSize: '14px' }}>Insurance fare</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(fareData.insurance_amount).toFixed(2)}</p>
                                                                    </td>
                                                                </tr>
                                                            </> : <></>}
                                                            <tr>
                                                                <td colSpan={3}><hr /></td>
                                                            </tr>
                                                            {fareData.surgeprice.length > 0 ? fareData.surgeprice.map((data, i) => {
                                                                return (<>
                                                                    <tr>
                                                                        <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.surge_amount).toFixed(2)}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3}><hr /></td>
                                                                    </tr></>)
                                                            }) : <></>}
                                                            {fareData != '' ? <>
                                                                {fareData.addon_data.length > 0 ? fareData.addon_data.map((data, i) => {
                                                                    return (<><tr>
                                                                        <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td colSpan={3}><hr /></td>
                                                                        </tr></>)
                                                                }) : <></>}</> : <></>
                                                            }</> : <>
                                                            <tr>
                                                                <td style={{ fontSize: '14px' }}>Base Fare</td>
                                                                <td>:</td>
                                                                <td>
                                                                    <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(0).toFixed(2)}</p>
                                                                </td>
                                                            </tr></>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-12'>
                                                <div className="row justify-content-center">
                                                    <div className="col-md-12 px-4">
                                                        <div className="row">
                                                            <table cellSpacing={3}>
                                                                <tr>
                                                                    <td className='dist-fare-head'>Distance Fare</td>
                                                                    {fareData != '' ? <>
                                                                        <td className="mb-0 dist-charge" id="fare-distance"><span>S$</span> {parseFloat(fareData.distance_price).toFixed(2)}</td>
                                                                        <td className="mb-0 dist-time" id="fare-distance-text">( {fareData.total_distance} KMS )</td></> : <>
                                                                        <td className="mb-0 dist-charge" id="fare-distance"><span>S$</span> {parseFloat(0).toFixed(2)}</td>
                                                                        <td className="mb-0 dist-time" id="fare-distance-text">( 0 KMS )</td></>}
                                                                </tr>

                                                                <tr>
                                                                    <td className='dist-fare-head'>Time Duartion</td>
                                                                    {fareData != '' ? <>
                                                                        <td className="mb-0 dist-charge" id="fare-min"><span>S$</span> {parseFloat(fareData.duration_price).toFixed(2)}</td>
                                                                        <td className="mb-0 dist-time" id="fare-min-text">( {fareData.total_minute} Mins )</td></> : <>
                                                                        <td className="mb-0 dist-charge" id="fare-min"><span>S$</span> {parseFloat(0).toFixed(2)}</td>
                                                                        <td className="mb-0 dist-time" id="fare-min-text">( 0 Mins )</td></>}
                                                                </tr>

                                                                <tr>
                                                                    <td className='dist-fare-head'>Sub Total</td>
                                                                    {fareData != '' ? <>
                                                                        {fareData.capped_status == 1 ? <>
                                                                            <td className="mb-0 dist-charge" id="fare-sub" style={{ textDecoration: 'line-through' }}><span>S$</span> {parseFloat(fareData.sub_total).toFixed(2)}</td>
                                                                            <td className="mb-0 dist-charge" id="fare-sub-cap"><span>S$</span> {parseFloat(fareData.cap_price).toFixed(2)} <span>(Capped)</span></td>
                                                                            <td className="mb-0 dist-time">Approx</td>
                                                                        </> : <>
                                                                            <td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(fareData.sub_total).toFixed(2)}</td>
                                                                        </>}
                                                                    </> : <><td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(0).toFixed(2)}</td></>}
                                                                </tr>
                                                                {fareData != "" && fareData.gst != 0 ?
                                                                <tr>
                                                                    <td className='dist-fare-head'>GST</td>
                                                                    {fareData != '' ? <>
                                                                    <td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(fareData.gst).toFixed(2)}</td>
                                                                    </> : <><td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(0).toFixed(2)}</td></>}
                                                                </tr> : ""}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* extra stop cost */}

                                {fareData != '' && fareData.extra_stop_status == 1 ? <>
                                    <div id="stopFare-div">
                                        <div className="row justify-content-center">
                                            <div className="col-md-10 col-10 mt-2">
                                                <div className='d-flex justify-content-center fare-total'>Extra Stop Fare : S$&nbsp;&nbsp;{parseFloat(fareData.overall_extra_stop_cost).toFixed(2)}</div>
                                            </div>
                                        </div>
                                    </div></> : <></>}


                                {/* <div className="row justify-content-center">
                                    <div className="col-10">
                                        <hr className="fare-hr" />
                                    </div>
                                </div> */}

                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-12 mt-2">
                                        <div className='d-flex justify-content-center fare-total'>Total Price : S$&nbsp;&nbsp;{parseFloat(FareTotalAmount).toFixed(2)}</div>
                                    </div>
                                </div>

                                {geoFencetoast == 1 ? <div>
                                    <p className="text-center labelgeo pt-3">* Pickup location fall inside the geofencing, Click on Add cart to see further details</p>
                                </div> : <></>}
                            </div>

                            <div className="form-group mb-3 comment">
                                <textarea className="form-control" rows="1" placeholder="Comments if anything" name='comment' id="comment" onChange={inputChange}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="d-flex justify-content-between">
                                <h5>Return Trip Booking</h5>
                                <label className="switch">
                                    <input type="checkbox" id="returnCheck" onChange={ReturnSwitch} />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            <div className={ReturnDiv == 0 ? 'ReturnDiv' : ''}>
                                <div className='row mb-1'>
                                    {/* <p>Pick Date/Time</p> */}
                                    <div className='col-lg-8 col-12 mt-1'>
                                        <div className="inner-addon right-addon">
                                            <i className="glyphicon glyphicon-user fa fa-calendar textColor"></i>
                                            {/* <input type="text" className="form-control rounded-pill" placeholder='Date'/> */}
                                            <DatePicker
                                                selected={ReSchDate}
                                                onChange={onReDateChange}
                                                dateFormat='yyyy-MM-dd'
                                                minDate={new Date}
                                                className='form-control date-she'
                                                placeholderText='Date'
                                                name='returnScheduleDate'
                                                disabled={ReturnDiv == 0 ? true : false} />
                                            <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.rdate}</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12 mt-1'>
                                        <div className="inner-addon right-addon">
                                            <i className="glyphicon glyphicon-user fa fa-clock textColor"></i>
                                            {/* <input type="text" className="form-control rounded-pill" placeholder='Time'/> */}
                                            <Datetime dateFormat={false}
                                                inputProps={ReturnDiv == 0 ? timeProps : timePropsE}
                                                onChange={onReTimeChange}
                                                value={ReSchTime}
                                                timeConstraints={timeConstraints}
                                                name='returnScheduleTime'
                                            />
                                            <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.rtime}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className='col-lg-8'>
                                        {/* <label className="form-label">Pickup Location</label> */}
                                        <Autocomplete onLoad={rfromLoad} onPlaceChanged={onrFromPlaceChanged} options={AutocompleteRestrict}>
                                            <input type="text" className="form-control rounded-pill" placeholder='Pickup Location' disabled={ReturnDiv == 0 ? true : false} defaultValue={ReturnDiv == 0 ? '' : drop_name} />
                                        </Autocomplete>
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.rpickup}</span>
                                    </div>
                                    <div className='col-lg-4'>
                                        <input type="button" className="btn rounded-pill billCart" disabled={ReturnDiv == 0 || ReStopDivs.length == 2 ? true : false} value='Add Stop' onClick={ReAddStop} />
                                    </div>
                                </div>

                                {ReStopDivs.map((data, i) => {
                                    return (
                                        <div>
                                            <div className="row mb-2">
                                                <div className="col-lg-8 col-10">
                                                    <Autocomplete onPlaceChanged={() => RehandleChange(inputRefs.current[i]?.getPlace(), i)} onLoad={(autocomplete) => fromRhandleChange(autocomplete, i)} options={AutocompleteRestrict}>
                                                        <input type="text" className="form-control rounded-pill" placeholder={`Return Stop ${i + 1}`} disabled={ReturnDiv == 0 ? true : false} />
                                                    </Autocomplete>
                                                </div>
                                                <div className="col-lg-4 col-2 d-flex align-items-center">
                                                    <i className="fa fa-times-circle" onClick={() => ReRemoveStop(i)}></i>
                                                </div>
                                            </div>
                                            <span className="error-field text-dark mb-3" style={{ marginLeft: "0.5rem" }}>{formError['rstop' + (i + 1)]}</span>
                                        </div>
                                    )
                                })}

                                {/* <div className="text-end">
                                    <input type="button" className="btn rounded-pill billCart" disabled={ReturnDiv == 0 || ReStopDivs.length == 2 ? true : false} value='Add Stop' onClick={ReAddStop} />
                                </div> */}
                                <div className='row'>
                                    <div className="col-8 mb-1">
                                        {/* <label className="form-label">Drop Location</label> */}
                                        <Autocomplete onLoad={rtoLoad} onPlaceChanged={onrToPlaceChanged} options={AutocompleteRestrict}>
                                            <input type="text" className="form-control rounded-pill" placeholder='Drop Location' disabled={ReturnDiv == 0 ? true : false} defaultValue={ReturnDiv == 0 ? '' : pick_name} />
                                        </Autocomplete>
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.rdrop}</span>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        {/* <label className="form-label">Vehicle Type</label> */}
                                        <input type="text" className="form-control rounded-pill" value={ReturnDiv == 0 ? '' : rvehicle_type} disabled placeholder='Vehicle Type' />
                                    </div>
                                    <div className={AddonShow == 1 ? 'col-lg-4 col-md-4 col-12' : 'd-none'}>
                                        {/* <span className="no_of" style={{ color: 'white' }}>&nbsp;</span> */}
                                        <div class="form-check d-flex justify-content-between">
                                            <div>
                                                <input class="form-check-input" type="checkbox" id="addon" name="addon" onChange={AddonModelOpenRe} checked={ReAddonData.length > 0 ? true : false} disabled={ReturnDiv == 0 ? true : false} />
                                                <label class="form-check-label">
                                                    Add-on Services
                                                </label>
                                                <i className='fa fa-circle-info ms-1' data-tooltip-id="tooltip-addon-return"></i>
                                            </div>
                                            <p className={ReAddonData.length > 0 ? 'text-end addon-edit' : 'text-end addon-edit d-none'} onClick={AddonModelEditRe}>Edit</p>
                                            <ReactTooltip id="tooltip-addon-return" place="top">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {ReAddonData.length > 0 ? ReAddonData.map((item, key) => {
                                                        return (
                                                            <span>{item.name} - {item.count}</span>
                                                        )
                                                    }) : <><p>No add-on selected</p></>}
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <input className="form-check-input" type='checkbox' id='return_insurance' name = 'return_insurance' onChange={handleChangeReturnInsurance}/>
                                    <label className="form-check-label ms-1 me-1">Insurance</label>
                                    <i className='fa fa-circle-info ms-1' data-tooltip-id="tooltip-insurance"></i>
                                    <ReactTooltip id="tooltip-insurance" place="top">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span>Insurance allows free cancellation up to {insCancelHour} hours from the service time.</span>
                                            <span>For a small fee, it gives you a greater flexibility!</span>
                                        </div>
                                    </ReactTooltip>
                                </div>
                                <div className="confirnBook mb-1">
                                    <p className="text-center confirm-title pt-2">Fare Breakdown</p>
                                    <div className="row justify-content-center">
                                        <div className="col-md-11 col-11 pt-2">
                                            <div className='row'>
                                                <div className='col-lg-6 col-12 checkout p-2'>
                                                    <div className="container surge_con" id={refareData != '' && (refareData.surgeprice.length > 0 || refareData.addon_data.length > 0) ? 'fare-scroll' : ''}>
                                                        <table id="fare-table">
                                                            {refareData != '' ? <>
                                                                <tr>
                                                                    <td style={{ fontSize: '14px' }}>Base Fare</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(refareData.base_fare).toFixed(2)}</p>
                                                                    </td>
                                                                </tr>
                                                                {refareData.insurance_amount != 0 ? <>
                                                                <tr>
                                                                    <td style={{ fontSize: '14px' }}>Insurance fare</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(refareData.insurance_amount).toFixed(2)}</p>
                                                                    </td>
                                                                </tr>
                                                                </> : <></>}
                                                                <tr>
                                                                    <td colSpan={3}><hr /></td>
                                                                </tr>
                                                                {refareData.surgeprice.length > 0 ? refareData.surgeprice.map((data, i) => {
                                                                    return (<>
                                                                        <tr>
                                                                            <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.surge_amount).toFixed(2)}</p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={3}><hr /></td>
                                                                        </tr></>)
                                                                }) : <></>}</> : <>
                                                                <tr>
                                                                    <td style={{ fontSize: '14px' }}>Base Fare</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(0).toFixed(2)}</p>
                                                                    </td>
                                                                </tr></>
                                                            }
                                                            {refareData != '' ? <>
                                                                {refareData.addon_data.length > 0 ? refareData.addon_data.map((data, i) => {
                                                                    return (<><tr>
                                                                        <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td colSpan={3}><hr /></td>
                                                                        </tr></>)
                                                                }) : <></>}</> : <></>
                                                            }
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-12'>
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-12 px-4">
                                                            <div className="row">
                                                                <table cellSpacing={3}>
                                                                    <tr>
                                                                        <td className='dist-fare-head'>Distance Fare</td>
                                                                        {refareData != '' ? <>
                                                                            <td className="mb-0 dist-charge" id="fare-distance"><span>S$</span> {parseFloat(refareData.distance_price).toFixed(2)}</td>
                                                                            <td className="mb-0 dist-time" id="fare-distance-text">( {refareData.total_distance} KMS )</td></> : <>
                                                                            <td className="mb-0 dist-charge" id="fare-distance"><span>S$</span> {parseFloat(0).toFixed(2)}</td>
                                                                            <td className="mb-0 dist-time" id="fare-distance-text">( 0 KMS )</td></>}
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='dist-fare-head'>Time Duartion</td>
                                                                        {refareData != '' ? <>
                                                                            <td className="mb-0 dist-charge" id="fare-min"><span>S$</span> {parseFloat(refareData.duration_price).toFixed(2)}</td>
                                                                            <td className="mb-0 dist-time" id="fare-min-text">( {refareData.total_minute} Mins )</td></> : <>
                                                                            <td className="mb-0 dist-charge" id="fare-min"><span>S$</span> {parseFloat(0).toFixed(2)} </td>
                                                                            <td className="mb-0 dist-time" id="fare-min-text">( 0 Mins )</td></>}
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='dist-fare-head'>Sub Total</td>
                                                                        {refareData != '' ? <>
                                                                            {refareData.capped_status == 1 ? <>
                                                                                <td className="mb-0 dist-charge" id="fare-sub" style={{ textDecoration: 'line-through' }}><span>S$</span> {parseFloat(refareData.sub_total).toFixed(2)}</td>
                                                                                <td className="mb-0 dist-charge" id="fare-sub-cap"><span>S$</span> {parseFloat(refareData.cap_price).toFixed(2)} <span>(Capped)</span></td>
                                                                                <td className="mb-0 dist-time">Approx</td>
                                                                            </> : <>
                                                                                <td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(refareData.sub_total).toFixed(2)}</td>
                                                                            </>}
                                                                        </> : <><td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(0).toFixed(2)}</td></>}
                                                                    </tr>
                                                                    {refareData != '' && refareData.gst != 0 ? 
                                                                    <tr>
                                                                        <td className='dist-fare-head'>GST</td>
                                                                        {refareData != '' ? <>
                                                                        <td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(refareData.gst).toFixed(2)}</td>
                                                                        </> : <><td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(0).toFixed(2)}</td></>}
                                                                    </tr> : ""}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* extra stop cost */}

                                    {refareData != '' && refareData.extra_stop_status == 1 ? <>
                                        <div id="stopFare-div">
                                            {/* <div className="row justify-content-center">
                                                <div className="col-10">
                                                    <hr className="fare-hr" />
                                                </div>
                                            </div> */}
                                            <div className="row justify-content-center">
                                                <div className="col-md-10 col-10 mt-2">
                                                    <div className='d-flex justify-content-center fare-total'>Extra Stop Fare : S$&nbsp;&nbsp;{parseFloat(refareData.overall_extra_stop_cost).toFixed(2)}</div>
                                                </div>
                                            </div>
                                        </div></> : <></>}

                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-12 mt-2">
                                            <div className='d-flex justify-content-center fare-total'>Total Price : S$&nbsp;&nbsp;{parseFloat(ReFareTotalAmount).toFixed(2)}</div>
                                            {/* <div className="row">
                                        <div className="col-5 fare-total p-0">
                                            Total Price
                                        </div>
                                        <div className="col-1 fare-total">
                                            :
                                        </div>
                                        <div className="col-6 fare-list1 p-0">
                                            <span id="fare-total">{parseFloat(ReFareTotalAmount).toFixed(2)} </span>&nbsp;&nbsp;SGD
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>

                                    {geoFencetoastRe == 1 ? <div>
                                        <p className="text-center labelgeo pt-3">* Pickup location fall inside the geofencing, Click on Add cart to see further details</p>
                                    </div> : <></>}
                                </div>

                                <div className="form-group mb-3 comment">
                                    <textarea className="form-control" rows="1" placeholder="Comments if anything" id="rcomment" name="rcomment" disabled={ReturnDiv == 0 ? true : false}></textarea>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="text-end">
                        {cart_button == 1 ? <div className='d-flex justify-content-end'><ThreeDots
                            height="40"
                            width="40"
                            radius="9"
                            color="#ff8901"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        /></div> :
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button type="submit" className="btn rounded-pill billCart" onClick={ScheduleNow}>Add Cart</button>
                                {cartData.length > 0 ? <input type="button" className="btn rounded-pill billCart" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-backdrop="false" value={"View Cart (" + cartData.length + ")"} /> : ''}
                            </div>}

                    </div>
                </form>
            </div>

            {/* geo fence model */}
            <>
                <div className='d-none'>
                    <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#GeoFence" id="GeoFencebtn" value='' />
                </div>
                <div className="modal fade" id="GeoFence" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="VerifyPhoneLabel" aria-hidden="true" data-bs-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header add-modal">
                                <h5 className="modal-title" id="staticBackdropLabel">Airport Details</h5>
                                <i className="fa fa-window-close" data-bs-dismiss="modal" aria-label="Close" id="Geoclose" onClick={geoFrom}></i>
                            </div>
                            <form method="post" autoComplete="off">
                                <div className="modal-body geo-body">
                                    <div className='row'>
                                        <div className='col-lg-6 col-12'>
                                            <div className="inner-addon right-addon mb-3">
                                                <label className="form-label verifyLabel">Select Pickup Date</label>
                                                <DatePicker
                                                    selected={AirDate}
                                                    onChange={onDateChangeAir}
                                                    dateFormat='yyyy-MM-dd'
                                                    minDate={new Date}
                                                    className='form-control'
                                                    placeholderText='Select Pickup Date'
                                                />
                                                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.dateAir}</span>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="mb-3">
                                                <label className="form-label verifyLabel">Additional Buffer (minutes) <i class="fa-solid fa-circle-info" data-tooltip-id="tooltip-buffer"></i></label>
                                                <select className="form-select rounded-pill" name="arrival_time" onChange={inputChangeAir}>
                                                    <option value="" selected>Choose Buffer Minutes</option>
                                                    <option value="0 mins">0 MIN</option>
                                                    <option value="10 mins">10 MINS</option>
                                                    <option value="20 mins">20 MINS</option>
                                                    <option value="30 mins">30 MINS</option>
                                                    <option value="40 mins">40 MINS</option>
                                                    <option value="50 mins">50 MINS</option>
                                                    <option value="60 mins">60 MINS</option>
                                                    <option value="70 mins">70 MINS</option>
                                                    <option value="80 mins">80 MINS</option>
                                                    <option value="90 mins">90 MINS</option>
                                                    <option value="100 mins">100 MINS</option>
                                                    <option value="110 mins">110 MINS</option>
                                                    <option value="120 mins">120 MINS</option>
                                                </select>
                                                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.arrivalAir}</span>
                                            </div>
                                            <ReactTooltip id="tooltip-buffer" place="top">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span>Time for the driver to reach</span>
                                                    <span>airport after your flight lands</span>
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-lg-6 col-12'>
                                            <div className="mb-3">
                                                <label className="form-label verifyLabel">Please Enter your Flight Number</label>
                                                <input type="text" className="form-control rounded-pill" placeholder='Your Flight Number' name='flight_number' onChange={inputChangeAir} />
                                                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.flightAir}</span>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="mb-3">
                                                <label className="form-label verifyLabel">&nbsp;</label>
                                                <input type="button" className="form-control btn rounded-pill billCart" value='Check Flight Details' onClick={checkFlight} />
                                            </div>
                                        </div>
                                    </div>

                                    {flightDiv == 1 && changeflightDiv == 1 ? <div className="row pt-3 ps-2 pe-2 mb-3 justify-content-center" id="flight_data">
                                        <div className="col-12 col-lg-12">
                                            <div className="row flight-details align-items-center">
                                                <div className="col-2 col-lg-1">
                                                    <i className="fa fa-plane" aria-hidden="true" style={{ fontSize: '30px', color: '#63881e' }}></i>
                                                </div>
                                                <div className="ms-1 col-8 col-lg-9">
                                                    <div>
                                                        <span>Flight Number</span>:&nbsp;<span id="f_number">{flight_no}</span><br />
                                                        <span>Scheduled Date/Time</span>:&nbsp;<span id="f_date_time">{flight_data.arrivalTime}</span><br />
                                                    </div>
                                                </div>
                                                <div className="col-2 col-lg-1">
                                                    <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: '30px', color: '#63881e' }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}

                                    <div className="passenger-dtls pt-3 mb-3">
                                        <div className="row pt-2 pb-3">
                                            <div className="col-6 col-lg-4">
                                                <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>No of Passengers</span>
                                                <div className="buttons d-flex align-items-center">
                                                    <p className='airport-decrement-btn' id="DecP" onClick={passengerOut} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; - &nbsp;</p>
                                                    <span className="number_padd" id="passenger-input">{Passenger}</span>
                                                    <p className='airport-increment-btn' id="DecP" onClick={passengerIn} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; + &nbsp;</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-4">
                                                <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>No of Wheelchairs</span>
                                                <div className="buttons d-flex align-items-center">
                                                    <p className='airport-decrement-btn' id="DecP" onClick={wheelchairOut} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; - &nbsp;</p>
                                                    <span className="number_padd" id="wheelchair-input">{Wheelchair}</span>
                                                    <p className='airport-increment-btn' id="DecP" onClick={wheelchairIn} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; + &nbsp;</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-4">
                                                <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>No of Luggages</span>
                                                <div className="buttons d-flex align-items-center">
                                                    <p className='airport-decrement-btn' id="DecP" onClick={luggageOut} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; - &nbsp;</p>
                                                    <span className="number_padd" id="luggage-input">{Luggage}</span>
                                                    <p className='airport-increment-btn' id="DecP" onClick={luggageIn} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; + &nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='text-center mb-3'>
                                        <input type="button" className="btn rounded-pill billCart" value='Choose Your Vehicle' onClick={ChooseVeh} disabled={vehiclebtn == 1 ? true : false} />
                                    </div> */}
                                    <div className={vehDiv == 0 ? 'ReturnDiv mb-2' : 'mb-2'}>
                                        <Select
                                            styles={Selectstyles}
                                            options={Vehicleoptions}
                                            placeholder="Vehicle Type"
                                            onChange={selectChangeAirr}
                                            isDisabled={flightDiv === 0 ? true : false}
                                            value={Vehicleoptions.filter(function (option) {
                                                return option.value === vehicle_typeArr;
                                            })}
                                        />
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.vehicleAir}</span>
                                    </div>
                                    <div className='p-2'>
                                        <input className="form-check-input" type='checkbox' id='air-insurance' name = 'air-insurance' onChange={handleChangeAirInsurance}/>
                                        <label className="form-check-label ms-1 me-1" style={{ color: 'white', fontSize: '14px' }} >Insurance</label>
                                        <i className='fa-solid fa-circle-info ms-1' style={{color: 'white'}} data-tooltip-id="tooltip-insurance"></i>
                                        <ReactTooltip id="tooltip-insurance" place="top" >
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span>Insurance allows free cancellation up to {insCancelHour} hours from the service time.</span>
                                                <span>For a small fee, it gives you a greater flexibility!</span>
                                            </div>
                                        </ReactTooltip>
                                    </div>
                                    {/* addon design */}
                                    <div className={GeoAddonShow == 1 ? 'addon-modal-body mb-2' : 'd-none'}>
                                        <div style={{ padding: '10px' }}>
                                            <h4 className='text-center'>Addon Services</h4>
                                            <div className='AddonTextBG'>
                                                <p>You can able to add maximum {MaxAddon} add on services from below</p>
                                            </div>
                                            <div className='AddonDiv'>
                                                {AddonList.length > 0 ? AddonList.map((addon, key) => (
                                                    <div className="accordion-item mb-3 addon-list" key={key}>
                                                        <div className="accordion-header">
                                                            <div className='row'>
                                                                <div className='col-2'>
                                                                    <img src={ApiKey.main_name + '/' + addon.image} className="Addon-image" />
                                                                </div>
                                                                <div className='col-5'>
                                                                    <p className="addon-p">{addon.title}</p>
                                                                </div>
                                                                <div className='col-5'>
                                                                    <div className="addon-buttons d-flex">
                                                                        <p className='airport-decrement-btn' id="DecP" onClick={() => minus({ addon_id: addon.id, name: addon.title })}> &nbsp; - &nbsp;</p>
                                                                        {cartItems.length > 0 ? (
                                                                            <span className="number_padd" id="wheelchair-input">
                                                                                {cartItems
                                                                                    .filter(item => item.addon_id === addon.id)
                                                                                    .map((item, key) => item.count)
                                                                                    .reduce((sum, count) => sum + count, 0)
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <span className="number_padd" id="wheelchair-input">0</span>
                                                                        )}



                                                                        <p className='airport-increment-btn' id="DecP" onClick={() => plus({ addon_id: addon.id, name: addon.title })}> &nbsp; + &nbsp;</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <p>Max {addon.max_number} Counts</p>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <p>SGD {addon.price}/Count</p>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="true" aria-controls={`collapse${key}`}><i className='fa fa-circle-info addon-info'></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id={`collapse${key}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample" style={{ padding: '10px' }}>
                                                            <div className="accordion-body" style={{ textAlign: 'left' }}>
                                                                <p className='p-0 m-0'>Description:</p>
                                                                <p className="text-left" dangerouslySetInnerHTML={{ __html: addon.description }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : <></>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='container farecon'>
                                        <div className="confirnBook p-2 mb-3">
                                            <p className="text-center confirm-title pt-3">Fare Breakdown</p>
                                            <div className={fareDataAir != '' && (fareDataAir.surgeprice.length > 0 || fareDataAir.addon_data.length > 0) ? 'row justify-content-center' : 'd-none'} id="surge-div">
                                                <div className="col-md-10 col-11 px-4 pt-4 checkout">
                                                    <div className="container" id="fare-scroll">
                                                        <table id="fare-table">
                                                            {fareDataAir != '' ? <>
                                                                {fareDataAir.surgeprice.length > 0 ? fareDataAir.surgeprice.map((data, i) => {
                                                                    return (<>
                                                                        <tr>
                                                                            <td>{data.name}</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <p className='mb-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={3}><hr /></td>
                                                                        </tr></>)
                                                                }) : <></>}</> : <></>
                                                            }
                                                            {fareDataAir != '' ? <>
                                                                {fareDataAir.addon_data.length > 0 ? fareDataAir.addon_data.map((data, i) => {
                                                                    return (<><tr>
                                                                        <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td colSpan={3}><hr /></td>
                                                                        </tr></>)
                                                                }) : <></>}</> : <></>
                                                            }
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center" id="air-div">
                                                <div className="col-md-12 px-4 py-4">
                                                    <div className="row">
                                                        <div className={fareDataAir != '' &&fareDataAir.gst != 0 ? "col-4 text-center dist-fare" : "col-6 text-center dist-fare"}>
                                                            <p className="dist-fare-head">Base Fare</p>
                                                            {fareDataAir != '' ? <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(fareDataAir.base_fare).toFixed(2)} </p> : <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(0).toFixed(2)} </p>}
                                                        </div>
                                                        <div className={fareDataAir != '' &&fareDataAir.gst != 0 ? "col-4 text-center dist-fare" : "col-6 text-center"} id="air-sub">
                                                            <p className="dist-fare-head">Sub Total</p>
                                                            {fareDataAir != '' ? <>
                                                                {fareDataAir.capped_status == 1 ? <>
                                                                    <p className="mb-0 dist-charge" id="fare-sub" style={{ textDecoration: 'line-through' }}><span>S$</span> {parseFloat(fareDataAir.sub_total).toFixed(2)}</p>
                                                                    <p className="mb-0 dist-charge" id="fare-sub-cap"><span>S$</span> {parseFloat(fareDataAir.cap_price).toFixed(2)} </p>
                                                                </> : <>
                                                                    <p className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(fareDataAir.sub_total).toFixed(2)}</p>
                                                                </>}</> : <>
                                                                <p className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(0).toFixed(2)}</p></>}
                                                        </div>
                                                        {fareDataAir != '' && fareDataAir.gst != 0 ? 
                                                        <div className="col-4 text-center ">
                                                            <p className="dist-fare-head">GST</p>
                                                            {fareDataAir != '' ? <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(fareDataAir.gst).toFixed(2)} </p> : <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(0).toFixed(2)} </p>}
                                                        </div> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            {fareDataAir.extra_stop_status == 1 ? <>
                                                <div id="stopFare-div-air">
                                                    <div className="row justify-content-center">
                                                        <div className="col-10">
                                                            <hr className="fare-hr" />
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-10 col-10 px-4 py-4">
                                                            <div className="row">
                                                                <div className="col-5 p-0" style={{ color: '#212021' }}>Extra Stop Fare</div>
                                                                <div className="col-1" style={{ color: '#212021' }}>:</div>
                                                                <div className="col-4 p-0" style={{ color: '#212021' }}>
                                                                    S$&nbsp;&nbsp;{parseFloat(fareDataAir.overall_extra_stop_cost).toFixed(2)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : <></>}
                                            <div className="row justify-content-center">
                                                <div className="col-10">
                                                    <hr className="fare-hr" />
                                                </div>
                                            </div>
                                            <div className={fareDataAir != '' && fareDataAir.insurance_amount != 0 ? 'col-12 text-center' : 'd-none'}>
                                                <p className="dist-fare-head m-0">Insurance Fare</p>
                                                <p className="m-0 dist-charge" id="m-fare-duration"> <span>S$</span> {parseFloat(fareDataAir.insurance_amount).toFixed(2)}</p>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-md-10 col-12 px-4 py-4">
                                                    <div className='d-flex justify-content-center fare-total'>Total Price : {fareDataAir != '' ? <>S$&nbsp;&nbsp;{parseFloat(Math.ceil(fareDataAir.amount)).toFixed(2)}</> : <>S$&nbsp;&nbsp;{parseFloat(Math.ceil(0)).toFixed(2)}</>}</div>
                                                    {/* <div className="row">
                                                        <div className="col-5 fare-total p-0">
                                                            Total Price
                                                        </div>
                                                        <div className="col-1 fare-total">
                                                            :
                                                        </div>
                                                        <div className="col-6 fare-list1 p-0">
                                                            {fareDataAir != '' ? <><span id="ma-fare-total">{parseFloat(Math.ceil(fareDataAir.amount)).toFixed(2)} </span>&nbsp;&nbsp;SGD</> : <><span id="ma-fare-total">{parseFloat(Math.ceil(0)).toFixed(2)} </span>&nbsp;&nbsp;SGD</>}
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer geo-body">
                                    <div className="text-end">
                                        {cart_button == 1 ? <div className='d-flex justify-content-end'><ThreeDots
                                            height="40"
                                            width="40"
                                            radius="9"
                                            color="#ff8901"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        /></div> : <button type="submit" className="btn rounded-pill billCart" onClick={ScheduleNow}>Add Cart</button>}

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>

            {/* geo fence model return */}
            <>
                <div className='d-none'>
                    <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#GeoFenceReturn" id="GeoFenceReturnbtn" value='' />
                </div>
                <div className="modal fade" id="GeoFenceReturn" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="VerifyPhoneLabel" aria-hidden="true" data-bs-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header add-modal">
                                <h5 className="modal-title" id="staticBackdropLabel">Return Airport Details</h5>
                                <i className="fa fa-window-close" data-bs-dismiss="modal" aria-label="Close" id="RGeoclose" onClick={geoReturn}></i>
                            </div>
                            <form method="post" autoComplete="off">
                                <div className="modal-body geo-body">
                                    <div className='row'>
                                        <div className='col-lg-6 col-12'>
                                            <div className="inner-addon right-addon mb-3">
                                                <label className="form-label">Select Pickup Date</label>
                                                <DatePicker
                                                    selected={AirDateRe}
                                                    onChange={onDateChangeAirRe}
                                                    dateFormat='yyyy-MM-dd'
                                                    minDate={new Date}
                                                    className='form-control'
                                                    placeholderText='Select Pickup Date'
                                                    defaultValue={ReSchDate}
                                                />
                                                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.dateAirRe}</span>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="mb-3">
                                                <label className="form-label">Additional Buffer (minutes) <i class="fa-solid fa-circle-info" data-tooltip-id="tooltip-buffer"></i></label>
                                                <select className="form-select rounded-pill" name="arrival_time" onChange={inputChangeAirRe}>
                                                    <option value="" selected>Choose Buffer Minutes</option>
                                                    <option value="0 mins">0 MIN</option>
                                                    <option value="10 mins">10 MINS</option>
                                                    <option value="20 mins">20 MINS</option>
                                                    <option value="30 mins">30 MINS</option>
                                                    <option value="40 mins">40 MINS</option>
                                                    <option value="50 mins">50 MINS</option>
                                                    <option value="60 mins">60 MINS</option>
                                                    <option value="70 mins">70 MINS</option>
                                                    <option value="80 mins">80 MINS</option>
                                                    <option value="90 mins">90 MINS</option>
                                                    <option value="100 mins">100 MINS</option>
                                                    <option value="110 mins">110 MINS</option>
                                                    <option value="120 mins">120 MINS</option>
                                                </select>
                                                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.arrivalAirRe}</span>
                                            </div>
                                            <ReactTooltip id="tooltip-buffer" place="top">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span>Time for the driver to reach</span>
                                                    <span>airport after your flight lands</span>
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-lg-6 col-12'>
                                            <div className="mb-3">
                                                <label className="form-label">Please Enter your Flight Number</label>
                                                <input type="text" className="form-control rounded-pill" placeholder='Your Flight Number' name='flight_number' onChange={inputChangeAirRe} />
                                                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.flightAirRe}</span>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className="mb-3">
                                                <label className="form-label">&nbsp;</label>
                                                <input type="button" className="form-control btn rounded-pill billCart" value='Check Flight Details' onClick={checkFlightRe} />
                                            </div>
                                        </div>
                                    </div>

                                    {flightDivRe == 1 && changeflightDivRe == 1 ? <div className="row pt-3 ps-2 pe-2 mb-3 justify-content-center" id="flight_data">
                                        <div className="col-12 col-lg-12">
                                            <div className="row flight-details align-items-center">
                                                <div className="col-2 col-lg-1">
                                                    <i className="fa fa-plane" aria-hidden="true" style={{ fontSize: '30px', color: '#63881e' }}></i>
                                                </div>
                                                <div className="ms-1 col-8 col-lg-9">
                                                    <div>
                                                        <span>Flight Number</span>:&nbsp;<span id="f_number">{flight_noRe}</span><br />
                                                        <span>Scheduled Date/Time</span>:&nbsp;<span id="f_date_time">{flight_dataRe.arrivalTime}</span><br />
                                                    </div>
                                                </div>
                                                <div className="col-2 col-lg-1">
                                                    <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: '30px', color: '#63881e' }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}

                                    <div className="passenger-dtls pt-3 mb-3">
                                        <div className="row pt-2 pb-3">
                                            <div className="col-6 col-lg-4">
                                                <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>No of Passengers</span>
                                                <div className="buttons d-flex align-items-center">
                                                    <p className='airport-decrement-btn' id="DecP" onClick={passengerOutRe} style={flightDivRe == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; - &nbsp;</p>
                                                    <span className="number_padd" id="passenger-input">{PassengerRe}</span>
                                                    <p className='airport-increment-btn' id="DecP" onClick={passengerInRe} style={flightDivRe == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; + &nbsp;</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-4">
                                                <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>No of Wheelchairs</span>
                                                <div className="buttons d-flex align-items-center">
                                                    <p className='airport-decrement-btn' id="DecP" onClick={wheelchairOutRe} style={flightDivRe == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; - &nbsp;</p>
                                                    <span className="number_padd" id="wheelchair-input">{WheelchairRe}</span>
                                                    <p className='airport-increment-btn' id="DecP" onClick={wheelchairInRe} style={flightDivRe == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; + &nbsp;</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-4">
                                                <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>No of Luggages</span>
                                                <div className="buttons d-flex align-items-center">
                                                    <p className='airport-decrement-btn' id="DecP" onClick={luggageOutRe} style={flightDivRe == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; - &nbsp;</p>
                                                    <span className="number_padd" id="luggage-input">{LuggageRe}</span>
                                                    <p className='airport-increment-btn' id="DecP" onClick={luggageInRe} style={flightDivRe == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; + &nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='text-center mb-3'>
                                        <input type="button" className="btn rounded-pill billCart" value='Choose Your Vehicle' onClick={ChooseVehRe} disabled={vehiclebtnre == 1 ? true : false} />
                                    </div> */}
                                    <div className={vehDivRe == 0 ? 'ReturnDiv mb-2' : 'mb-2'}>
                                        <Select
                                            styles={Selectstyles}
                                            options={VehicleoptionsRe}
                                            placeholder="Vehicle Type"
                                            onChange={selectChangeAirrRe}
                                            // disabled={vehDiv == 0 ? true : false}
                                            isDisabled={flightDivRe == 0 ? true : false}
                                            value={VehicleoptionsRe.filter(function (option) {
                                                return option.value === vehicle_typeArrRe;
                                            })}
                                        />
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.vehicleAirRe}</span>
                                    </div>
                                    <div className='p-2'>
                                        <input className="form-check-input" type='checkbox' id='air-insurance' name = 'air-insurance' onChange={handleChangeReAirInsurance}/>
                                        <label className="form-check-label ms-1 me-1" style={{ color: 'white', fontSize: '14px' }} >Insurance</label>
                                        <i className='fa-solid fa-circle-info ms-1' style={{color: 'white'}} data-tooltip-id="tooltip-insurance"></i>
                                        <ReactTooltip id="tooltip-insurance" place="top" >
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span>Insurance allows free cancellation up to {insCancelHour} hours from the service time.</span>
                                                <span>For a small fee, it gives you a greater flexibility!</span>
                                            </div>
                                        </ReactTooltip>
                                    </div>
                                </div>
                                {/* addon design */}
                                <div className={GeoAddonShow == 1 ? 'addon-modal-body mb-2' : 'd-none'}>
                                    <div style={{ padding: '10px' }}>
                                        <h4 className='text-center'>Addon Services</h4>
                                        <div className='AddonTextBG'>
                                            <p>You can able to add maximum {MaxAddon} add on services from below</p>
                                        </div>
                                        <div className='AddonDiv'>
                                            {AddonList.length > 0 ? AddonList.map((addon, key) => (
                                                <div className="accordion-item mb-3 addon-list" key={key}>
                                                    <div className="accordion-header">
                                                        <div className='row'>
                                                            <div className='col-2'>
                                                                <img src={ApiKey.main_name + '/' + addon.image} className="Addon-image" />
                                                            </div>
                                                            <div className='col-5'>
                                                                <p className="addon-p">{addon.title}</p>
                                                            </div>
                                                            <div className='col-5'>
                                                                <div className="addon-buttons d-flex">
                                                                    <p className='airport-decrement-btn' id="DecP" onClick={() => minusre({ addon_id: addon.id, name: addon.title })}> &nbsp; - &nbsp;</p>
                                                                    {RecartItems.length > 0 ? (
                                                                        <span className="number_padd" id="wheelchair-input">
                                                                            {RecartItems
                                                                                .filter(item => item.addon_id === addon.id)
                                                                                .map((item, key) => item.count)
                                                                                .reduce((sum, count) => sum + count, 0)
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        <span className="number_padd" id="wheelchair-input">0</span>
                                                                    )}



                                                                    <p className='airport-increment-btn' id="DecP" onClick={() => plusre({ addon_id: addon.id, name: addon.title })}> &nbsp; + &nbsp;</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <p>Max {addon.max_number} Counts</p>
                                                            </div>
                                                            <div className='col-4'>
                                                                <p>SGD {addon.price}/Count</p>
                                                            </div>
                                                            <div className='col-4'>
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="true" aria-controls={`collapse${key}`}><i className='fa fa-circle-info addon-info'></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id={`collapse${key}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample" style={{ padding: '10px' }}>
                                                        <div className="accordion-body" style={{ textAlign: 'left' }}>
                                                            <p className='p-0 m-0'>Description:</p>
                                                            <p className="text-left" dangerouslySetInnerHTML={{ __html: addon.description }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <></>}
                                        </div>
                                    </div>
                                </div>
                                <div className='container farecon'>
                                    <div className="confirnBook p-2">
                                        <p className="text-center confirm-title pt-3">Fare Breakdown</p>
                                        <div className={refareDataAir != '' && (refareDataAir.surgeprice.length > 0 || refareDataAir.addon_data.length > 0) ? 'row justify-content-center' : 'd-none'} id="surge-div">
                                            <div className="col-md-10 col-11 px-4 pt-4 checkout">
                                                <div className="container" id="fare-scroll">
                                                    <table id="fare-table">
                                                        {refareDataAir != '' ? <>
                                                            {refareDataAir.surgeprice.length > 0 ? refareDataAir.surgeprice.map((data, i) => {
                                                                return (<>
                                                                    <tr>
                                                                        <td>{data.name}</td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <p className='mb-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3}><hr /></td>
                                                                    </tr></>)
                                                            }) : <></>}</> : <></>
                                                        }
                                                        {refareDataAir != '' ? <>
                                                            {refareDataAir.addon_data.length > 0 ? refareDataAir.addon_data.map((data, i) => {
                                                                return (<><tr>
                                                                    <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                                                    </td>
                                                                </tr>
                                                                    <tr>
                                                                        <td colSpan={3}><hr /></td>
                                                                    </tr></>)
                                                            }) : <></>}</> : <></>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center" id="air-div">
                                            <div className="col-md-12 px-4 py-4">
                                                <div className="row">
                                                    <div className={refareDataAir != '' &&refareDataAir.gst != 0 ? "col-4 text-center dist-fare" : "col-6 text-center dist-fare"}>
                                                        <p className="dist-fare-head">Base Fare</p>
                                                        {refareDataAir != '' ? <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(refareDataAir.base_fare).toFixed(2)} </p> : <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(0).toFixed(2)}</p>}
                                                    </div>
                                                    <div className={refareDataAir != '' &&refareDataAir.gst != 0 ? "col-4 text-center dist-fare" : "col-6 text-center"} id="air-sub">
                                                        <p className="dist-fare-head">Sub Total</p>
                                                        {refareDataAir != '' ? <>
                                                            {refareDataAir.capped_status == 1 ? <>
                                                                <p className="mb-0 dist-charge" id="fare-sub" style={{ textDecoration: 'line-through' }}><span>S$</span> {parseFloat(refareDataAir.sub_total).toFixed(2)}</p>
                                                                <p className="mb-0 dist-charge" id="fare-sub-cap"><span>S$</span> {parseFloat(refareDataAir.cap_price).toFixed(2)}</p>
                                                            </> : <>
                                                                <p className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(refareDataAir.sub_total).toFixed(2)}</p>
                                                            </>}</> : <>
                                                            <p className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(0).toFixed(2)}</p></>}
                                                    </div>
                                                    {refareDataAir != '' && refareDataAir.gst != 0 ? 
                                                    <div className="col-4 text-center ">
                                                        <p className="dist-fare-head">GST</p>
                                                        {refareDataAir != '' ? <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(refareDataAir.gst).toFixed(2)} </p> : <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(0).toFixed(2)} </p>}
                                                    </div> : "" }
                                                </div>
                                            </div>
                                        </div>
                                        {refareDataAir.extra_stop_status == 1 ? <>
                                            <div id="stopFare-div-air">
                                                <div className="row justify-content-center">
                                                    <div className="col-10">
                                                        <hr className="fare-hr" />
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-md-10 col-10 px-4 py-4">
                                                        <div className="row">
                                                            <div className="col-5 p-0" style={{ color: '#212021' }}>Extra Stop Fare</div>
                                                            <div className="col-1" style={{ color: '#212021' }}>:</div>
                                                            <div className="col-4 p-0" style={{ color: '#212021' }}>
                                                                S$&nbsp;&nbsp;{parseFloat(refareDataAir.overall_extra_stop_cost).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : <></>}
                                        <div className="row justify-content-center">
                                            <div className="col-10">
                                                <hr className="fare-hr" />
                                            </div>
                                        </div>
                                        <div className={refareDataAir != '' && refareDataAir.insurance_amount != 0 ? 'col-12 text-center' : 'd-none'}>
                                            <p className="dist-fare-head m-0">Insurance Fare</p>
                                            <p className="m-0 dist-charge" id="m-fare-duration"> <span>S$</span> {parseFloat(refareDataAir.insurance_amount).toFixed(2)}</p>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-md-10 col-12 px-4 py-4">
                                                <div className='d-flex justify-content-center fare-total'>Total Price : {refareDataAir != '' ? <>S$&nbsp;&nbsp;{parseFloat(Math.ceil(refareDataAir.amount)).toFixed(2)}</> : <>S$&nbsp;&nbsp;{parseFloat(Math.ceil(0)).toFixed(2)}</>}</div>
                                                {/* <div className="row">
                                                    <div className="col-5 fare-total p-0">
                                                        Total Price
                                                    </div>
                                                    <div className="col-1 fare-total">
                                                        :
                                                    </div>
                                                    <div className="col-6 fare-list1 p-0">
                                                        {refareDataAir != '' ? <><span id="ma-fare-total">{parseFloat(Math.ceil(refareDataAir.amount)).toFixed(2)} </span>&nbsp;&nbsp;SGD</> : <><span id="ma-fare-total">{parseFloat(Math.ceil(0)).toFixed(2)} </span>&nbsp;&nbsp;SGD</>}
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer geo-body">
                                    <div className="text-end">
                                        {cart_button == 1 ? <div className='d-flex justify-content-end'><ThreeDots
                                            height="40"
                                            width="40"
                                            radius="9"
                                            color="#ff8901"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        /></div> : <button type="submit" className="btn rounded-pill billCart" onClick={ScheduleNow}>Add Cart</button>}

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>

            {/* verify button */}
            <div className='d-none'>
                <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#AddonData" data-bs-backdrop="false" id="AddonDataModel" />
            </div>
            {!hasRendered ? <></> : <AddonModel AddonData={VehicleAddonData} SelectAddonData={AddonTripType == 'from' ? AddonData : ReAddonData} getAddon={getAddon} />}
        </>
    )
}

export default ScheduleBook