import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import logo from '../../assets/image/login-logo-new.png'
import '../../styles/Verify.css'
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { ApiCall } from '../../common/Apikey';
import { ThreeDots, Watch } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function VerifyOtp() {

  let navigate = useNavigate();

  const [otp, setOtp] = useState('');
  const [passenger_token, setPassengerToken] = useState('');
  const [page_status, setPageStatus] = useState(1)
  const [submit, setSubmit] = useState(0)

  let { id } = useParams();

  const CheckTrip = async (access_token) => {
    let data = {
      token: access_token,
    };
    await ApiCall('GuestEditVerify', data).then((data) => {
      if (data.message == "not edit") {
        let path = `../noEdit`;
        navigate(path);
      } else if (data.message == 'not found') {
        let path = `../notFound`;
        navigate(path);
      }else {
        // setPageStatus(1)
        toast.success('OTP Sent To Your Mobile Number', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    setPassengerToken(id)
    // CheckTrip(id)
  }, [id])

  const VerifyOtp = async (event) => {
    event.preventDefault()
    setSubmit(1)

    if (otp == "") {
      toast.error('Enter OTP', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSubmit(0)
      return false
    }

    let data = {
      token: passenger_token,
      verifyOtp: otp,
    };
    await ApiCall('GuestOtpCheck', data).then((data) => {
      if (data.message == "success") {
        toast.success('OTP Verified Successfully', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          let path = `../Booking_details?id=`+data.data+`&access_token=`+data.access_token;
          navigate(path);
        }, 3000);
      } else {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setSubmit(0)
      }
    }).catch(err => console.log(err));
  }


  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {page_status == 1 ? <section className="verify-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 pt-5">
              <div className="sec-title">
                <img className="" src={logo} alt="" />
                <h1 className="">Vimo Services</h1>
              </div>
              <form autocomplete="off">
                <div className="login-form">
                  <h2>Booking Verification OTP</h2>
                  <div className="row justify-content-center mt-3">
                    <p className="col-md-9">Enter your valid verification code</p>
                  </div>
                  <div className="px-3 pt-3">
                      {submit == 1 ? <div className='d-flex justify-content-end'><ThreeDots
                        height="40"
                        width="40"
                        radius="9"
                        color="#ff8901"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      /></div> : <button type="button" className="btn rounded-pill billCart" onClick={()=> CheckTrip(passenger_token)}>SendOTP</button>}
                  </div>
                  <div className="enter-phone form-outline px-3">
                    <label className="form-label" for="typeNumber">Enter OTP</label>
                    <div>
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        isInputNum
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        placeholder='****'
                        inputStyle='m-2 form-control w-75'
                        containerStyle='d-flex justify-content-between'
                      />
                    </div>
                  </div>
                  <div className="text-end px-3 pt-3">
                    {submit == 1 ? <div className='d-flex justify-content-end'><ThreeDots
                      height="40"
                      width="40"
                      radius="9"
                      color="#ff8901"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    /></div> : <button type="submit" className="btn rounded-pill billCart" onClick={VerifyOtp}>Proceed</button>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> : <div className='container h-100'>
        <div className='d-flex justify-content-md-center align-items-center vh-100'>
          <Watch
            height="80"
            width="80"
            radius="48"
            color="#ca2733"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
          <br />
        </div>
      </div>}
    </div>
  )
}

export default VerifyOtp