import { CardElement, Elements, ElementsConsumer, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import $ from 'jquery';
import { ApiCall, ApiKey } from '../common/Apikey';
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';

function CardModule(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [disable, setDisable] = useState(false);
  // const [indent_id, setIndentId] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault();

    setDisable(true);

    let data = {
      amount: props.amount,
      email: props.email,
      phone_number: props.phoneNumber
    }
    var indent_id = "";
    await ApiCall('StripPayment3D', data).then((data) => {
      if (data.message === "success") {
        // setIndentId(data.client_secret)
        indent_id = data.client_secret
        let paymentIntentId = encodeURIComponent(data.id);
        localStorage.setItem("indent_id",paymentIntentId);
      }
    }).catch(err => console.log(err));

    // const indent_id = "pi_3NQrTUAf6IJVLww31Ggw6QhD_secret_6BzOkI46ZjiGctQMruO7MCwjo";

    if (indent_id) {
      const cardElement = elements.getElement(CardElement);
      const confirmPayment = await stripe.confirmCardPayment(
        indent_id,
        {
          payment_method: { card: cardElement }
        }
      );
      const { paymentIntent } = confirmPayment;
      if (paymentIntent && paymentIntent.status == 'succeeded') {
        $('#close').click();
        setTimeout(function () {
          elements.getElement(CardElement).clear();
          setDisable(false);
          props.getSuccess('sucess')
        }, 2000);

      } else {
        $('#close').click();
        setTimeout(function () {
          elements.getElement(CardElement).clear();
          setDisable(false);
          props.getSuccess('error')
        }, 2000);
      }
    } else {
      $('#close').click();
      setTimeout(function () {
        elements.getElement(CardElement).clear();
        setDisable(false);
        props.getSuccess('error')
      }, 2000);
    }
  }

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "black",
        fontSize: "16px",
        fontFamily: "sans-serif",
        fontSmoothing: "antialiased",
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#e5424d"
        }
      }
    },
    hidePostalCode: true
  };
  return (
    <div>
      <div
        className="modal fade modal-centered"
        id={props.id}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content cart-module-container">
            <div className="modal-header text-center model-top">
              <h3 className="modal-title fs-9 cart-module-heder" id="exampleModalLabel">
                {props.title}
              </h3>
              <button
                type="button"
                className="btn-close close-btn"
                data-bs-dismiss="modal"
                id="close"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body cart-module-body">
              <div>
                <h4 className="cardDetails">Card Details</h4>
              </div>
              <form
                onSubmit={handleSubmit}
              >
                <CardElement options={CARD_ELEMENT_OPTIONS} />
                {/* <span className="m-2  text-danger">{errorText}</span> */}
                <div className="submit-parent">
                  <button className="btn model-submit" type="button" onClick={(e) => { handleSubmit(e) }} disabled={disable}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardModule;
